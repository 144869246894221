const initialUser = {
  loggedIn: false,
  sessionToken: undefined
};

const user = (state = initialUser, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return {
        loggedIn: true,
        sessionToken: action.payload.data.token
      };

    case "LOGOUT":
      return initialUser;

    default:
      return state;
  }
};

export default user;
