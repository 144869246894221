import { BreadcrumbActionTypes } from "../actions/breadcrumbActions";
const initialState = {
  routes: [
    {
      path: "/",
      breadcrumbName: "Ana Sayfa"
    }
  ]
};

const breadcrumb = (state = initialState, action) => {
  switch (action.type) {
    case BreadcrumbActionTypes.SET_BREADCRUMB_ROUTES:
      return {
        routes: [...initialState.routes, ...action.payload]
      };

    case BreadcrumbActionTypes.RESET_BREADCRUMB_ROUTES:
      return initialState;

    default:
      return state;
  }
};

export default breadcrumb;
