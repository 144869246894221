import React, { Component } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import styled from "styled-components";
import * as yup from "yup";
import history from "../../../_core/history";
import * as accountActions from "../../../store/actions/accountActions";
import * as breadcrumbActions from "../../../store/actions/breadcrumbActions";
import accountTypes from "../../../constants/accountTypes";
import {
  defErrorModalProps,
  defSuccessModalProps,
  defConfirmModalProps
} from "../../../constants/defaultModalProps";

import {
  Card,
  Row,
  Col,
  Form,
  Select,
  Input,
  Button,
  Modal,
  message,
  Dropdown,
  Icon,
  Menu
} from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import {
  getAccountNameByValue,
  getAccountURLByValue,
  getTransactionTypesOfAccountTypeByValue
} from "../../../util/accountHelper";
import { getTransactionTypeObjByValue } from "../../../util/transactionHelper";

interface IProps {
  accountId?: string;
  accountType?: string;
  isNew: boolean;
  getAccount: (params: { accountId?: string }) => any;
  deleteAccount: (params: { accountId?: string }) => any;
  updateAccount: any;
  createAccount: any;
  setBreadcrumbRoutes: (routes: Route[]) => any;
}

interface IState {
  account: any;
  isEdit: boolean;
}

class AccountCEV extends Component<IProps, IState> {
  private formik = React.createRef<Formik>();

  constructor(props) {
    super(props);

    this.state = {
      account: {
        accountCode: "",
        accountName: "",
        accountType: "",
        lastName: "",
        firstName: "",
        personType: ""
      },
      isEdit: false
    };
  }

  componentDidMount = async () => {
    const { accountId, accountType, isNew } = this.props;
    const account = {
      ...this.state.account,
      accountType
    };
    this.setState({ account });
    if (!isNew) {
      const defaultAccount = (await this.props.getAccount({ accountId })).action.payload.data;
      const accountCode = defaultAccount.accountCode.split(`${accountType}.`)[1];
      const account = {
        ...defaultAccount,
        accountCode,
        accountType
      };
      this.setState({
        account
      });
    } else {
      this.setState({ isEdit: true });
    }
    this.setBreadcrumb();
  };

  setBreadcrumb = () => {
    const { accountType, isNew } = this.props;
    const { account } = this.state;
    const accountTypeURL = accountType ? getAccountURLByValue(accountType) : "";
    const accountTypeName = accountType ? getAccountNameByValue(accountType) || "" : "Tümü";
    const routes: Route[] = [
      {
        path: "/hesaplar",
        breadcrumbName: "Hesaplar"
      },
      {
        path: "/hesaplar/" + accountTypeURL,
        breadcrumbName: accountTypeName,
        children: [
          { path: "/hesaplar", breadcrumbName: "Tümü" },
          ...accountTypes.map(account => ({
            path: "/hesaplar/" + account.urlName,
            breadcrumbName: account.name
          }))
        ]
      },
      {
        path: "/hesaplar/" + accountTypeURL + "/" + (isNew ? "yeni" : account._id),
        breadcrumbName: isNew ? "Yeni" : account.accountName
      }
    ];
    this.props.setBreadcrumbRoutes(routes);
  };

  handleSubmit = async (values, { setSubmitting }) => {
    const { isNew } = this.props;
    const accountTypeUrl = getAccountURLByValue(values.accountType);

    if (values.accountType !== "customer" && values.accountType !== "supplier") {
      delete values.personType;
      if (values.accountType !== "employee") {
        delete values.firstName;
        delete values.lastName;
      } else {
        values.accountName = `${values.firstName} ${values.lastName}`;
      }
    } else {
      if (values.personType === "corporate") {
        delete values.firstName;
        delete values.lastName;
      } else {
        values.accountName = `${values.firstName} ${values.lastName}`;
      }
    }

    if (!isNew) {
      try {
        await this.props.updateAccount({
          ...values,
          accountCode: `${values.accountType}.${values.accountCode}`
        });
        this.formik.current!.resetForm();
        Modal.success({
          ...defSuccessModalProps,
          content: "Hesap güncellendi",
          onOk: () => {
            history.push("/hesaplar/" + accountTypeUrl);
          }
        });
      } catch (err) {
        Modal.error({ ...defErrorModalProps, content: err.message });
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await this.props.createAccount({
          ...values,
          accountCode: `${values.accountType}.${values.accountCode}`
        });
        setSubmitting(false);
        this.formik.current!.resetForm();
        Modal.success({
          ...defSuccessModalProps,
          content: "Hesap oluşturuldu.",
          onOk: () => {
            history.push("/hesaplar/" + accountTypeUrl);
          }
        });
      } catch (err) {}
    }
  };

  deleteAccount = async () => {
    const { accountId } = this.props;
    const { accountName, accountType } = this.state.account;
    Modal.confirm({
      ...defConfirmModalProps,
      content: `${accountName} adlı hesabı silmek istediğinizden emin misiniz?`,
      okText: "Sil",
      okType: "danger",
      onOk: async () => {
        const accountTypeUrl = getAccountURLByValue(accountType);
        try {
          const res = await this.props.deleteAccount({ accountId });
          if (res.data && res.data.status === "sucess") message.success("Hesap silindi.");
          history.push("/hesaplar/" + accountTypeUrl);
        } catch (err) {
          await Modal.error({ ...defErrorModalProps, content: err.message });
          await history.push("/hesaplar/" + accountTypeUrl + "/" + accountId + "/islemler");
        }
      }
    });
  };

  renderAdditionalFields = ({ values, errors, touched, handleChange, setFieldValue }) => {
    const { isEdit } = this.state;
    const accountTypeText = getAccountNameByValue(values.accountType);

    switch (values.accountType) {
      case "customer":
      case "supplier":
        return (
          <AdditionalRows>
            <Row>
              <Col>
                <Form.Item
                  label={`${accountTypeText} Tipi`}
                  validateStatus={errors.personType && touched.personType ? "error" : "success"}
                  help={errors.personType && touched.personType ? errors.personType : null}
                >
                  <SelectField
                    id="personType"
                    value={values.personType}
                    disabled={!isEdit}
                    onChange={value => setFieldValue("personType", value)}
                  >
                    {[
                      { name: "Birey", value: "person" },
                      { name: "Kurum", value: "corporate" }
                    ].map(type => (
                      <Select.Option key={type.value} value={type.value}>
                        {type.name}
                      </Select.Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </Col>
            </Row>
            {values.personType === "person" ? (
              <Row type="flex" justify="space-between">
                <Col span={12}>
                  <Form.Item
                    label="İsim"
                    validateStatus={errors.firstName && touched.firstName ? "error" : "success"}
                    help={errors.firstName && touched.firstName ? errors.firstName : null}
                  >
                    <InputField
                      name="firstName"
                      value={values.firstName}
                      disabled={!isEdit}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Soyisim"
                    validateStatus={errors.lastName && touched.lastName ? "error" : "success"}
                    help={errors.lastName && touched.lastName ? errors.lastName : null}
                  >
                    <InputField
                      name="lastName"
                      value={values.lastName}
                      disabled={!isEdit}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <Form.Item
                    colon={false}
                    label={`${accountTypeText} İsmi`}
                    validateStatus={errors.accountName && touched.accountName ? "error" : "success"}
                    help={errors.accountName && touched.accountName ? errors.accountName : null}
                  >
                    <InputField
                      name="accountName"
                      type="text"
                      value={values.accountName}
                      onChange={handleChange}
                      disabled={!isEdit}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item colon={false} label="Telefon Numarası">
                  <InputField
                    name="phoneNumber"
                    type="text"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  colon={false}
                  label="Mail Adresi"
                  help={errors.email && touched.email ? errors.email : null}
                >
                  <InputField
                    name="email"
                    type="mail"
                    value={values.email}
                    onChange={handleChange}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Adres">
                  <InputField
                    name="address"
                    value={values.address}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item label="Vergi Dairesi">
                  <InputField
                    name="taxAdmin"
                    value={values.taxAdmin}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="Vergi Numarası">
                  <InputField
                    name="taxNumber"
                    value={values.taxNumber}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </AdditionalRows>
        );
      case "bank":
        return (
          <AdditionalRows>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item label="Banka İsmi">
                  <InputField
                    name="accountName"
                    value={values.accountName}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="Şube İsmi">
                  <InputField
                    name="bankBranch"
                    value={values.bankBranch}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item label="Hesap Numarası">
                  <InputField
                    name="bankAccountNumber"
                    value={values.bankAccountNumber}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="Iban">
                  <InputField
                    name="bankIban"
                    value={values.bankIban}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item colon={false} label="Telefon Numarası">
                  <InputField
                    name="phoneNumber"
                    type="text"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  colon={false}
                  label="Mail Adresi"
                  help={errors.email && touched.email ? errors.email : null}
                >
                  <InputField
                    name="email"
                    type="mail"
                    value={values.email}
                    onChange={handleChange}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
          </AdditionalRows>
        );
      case "stock":
      case "service":
        return (
          <AdditionalRows>
            <Row>
              <Col>
                <Form.Item label={`${accountTypeText} İsmi`}>
                  <InputField
                    name="accountName"
                    value={values.accountName}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item label={`${accountTypeText} Türü`}>
                  <InputField
                    name="productType"
                    value={values.productType}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label={`${accountTypeText} Birimi`}>
                  <InputField
                    name="productUnit"
                    value={values.productUnit}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </AdditionalRows>
        );
      case "employee":
        return (
          <AdditionalRows>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item
                  label="İsim"
                  validateStatus={errors.firstName && touched.firstName ? "error" : "success"}
                  help={errors.firstName && touched.firstName ? errors.firstName : null}
                >
                  <InputField
                    name="firstName"
                    value={values.firstName}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  label="Soyisim"
                  validateStatus={errors.lastName && touched.lastName ? "error" : "success"}
                  help={errors.lastName && touched.lastName ? errors.lastName : null}
                >
                  <InputField
                    name="lastName"
                    value={values.lastName}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item colon={false} label="Telefon Numarası">
                  <InputField
                    name="phoneNumber"
                    type="text"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item
                  colon={false}
                  label="Mail Adresi"
                  help={errors.email && touched.email ? errors.email : null}
                >
                  <InputField
                    name="email"
                    type="mail"
                    value={values.email}
                    onChange={handleChange}
                    disabled={!isEdit}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={8}>
                <Form.Item label="Kimlik Numarası">
                  <InputField
                    name="employeeId"
                    value={values.employeeId}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Sgk Numarası">
                  <InputField
                    name="employeeSgk"
                    value={values.employeeSgk}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Sicil Numarası">
                  <InputField
                    name="employeeRecordId"
                    value={values.employeeRecordId}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Form.Item label="Çalıştığı Pozisyon">
                  <InputField
                    name="employeePosition"
                    value={values.employeePosition}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item label="Ödeme">
                  <InputField
                    name="employeePayment"
                    value={values.employeePayment}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={8}>
                <Form.Item label="Medeni Durum">
                  <SelectField
                    id="employeeMaritalStatus"
                    value={values.employeeMaritalStatus}
                    disabled={!isEdit}
                    onChange={value => setFieldValue("employeeMaritalStatus", value)}
                  >
                    {[
                      { name: "Evli", value: "married" },
                      { name: "Bekar", value: "single" }
                    ].map(status => (
                      <Select.Option key={status.value} value={status.value}>
                        {status.name}
                      </Select.Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Eşi çalışıyor mu">
                  <SelectField
                    id="employeePartnerStatus"
                    value={values.employeePartnerStatus}
                    disabled={!isEdit}
                    onChange={value => setFieldValue("employeePartnerStatus", value)}
                  >
                    {[
                      { name: "Evet", value: "true" },
                      { name: "Hayır", value: "false" }
                    ].map(status => (
                      <Select.Option key={status.value} value={status.value}>
                        {status.name}
                      </Select.Option>
                    ))}
                  </SelectField>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Çocuk Sayısı">
                  <InputField
                    name="employeeChild"
                    value={values.employeeChild}
                    disabled={!isEdit}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </AdditionalRows>
        );
      default:
        return (
          <Row>
            <Col>
              <Form.Item
                colon={false}
                label={`${accountTypeText} İsmi`}
                validateStatus={errors.accountName && touched.accountName ? "error" : "success"}
                help={errors.accountName && touched.accountName ? errors.accountName : null}
              >
                <InputField
                  name="accountName"
                  type="text"
                  value={values.accountName}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </Form.Item>
            </Col>
          </Row>
        );
    }
  };

  render() {
    const { accountId, isNew } = this.props;
    const { account, isEdit } = this.state;
    const { accountType, accountName } = account;
    const transactionsOfAccountType = getTransactionTypesOfAccountTypeByValue(accountType);
    const transactionTypeObject =
      accountId &&
      transactionsOfAccountType &&
      getTransactionTypeObjByValue(transactionsOfAccountType);
    const title = accountId
      ? accountName
      : accountType
      ? `${getAccountNameByValue(accountType)} Hesabı Oluştur`
      : "Hesap Oluştur";
    return (
      <Row type="flex" justify="center">
        <Col span={22}>
          <Formik
            enableReinitialize
            ref={this.formik}
            initialValues={{ ...account }}
            validationSchema={accountValidation}
            onSubmit={this.handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              isSubmitting,
              handleSubmit,
              resetForm
            }) => (
              <StyledCard
                title={title}
                style={{ marginTop: 30 }}
                bordered
                actions={
                  isEdit
                    ? [
                        <Button
                          onClick={() => {
                            resetForm();
                          }}
                          icon="undo"
                          disabled={!isEdit}
                        >
                          Temizle
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          icon="check"
                          disabled={isSubmitting || !isEdit}
                          onClick={() => {
                            handleSubmit();
                          }}
                        >
                          {!isNew ? "Güncelle" : "Kaydet"}
                        </Button>
                      ]
                    : []
                }
              >
                <Form>
                  {accountId && (
                    <Row
                      type="flex"
                      justify="space-between"
                      style={{ marginBottom: 20, display: "flex", flexWrap: "wrap" }}
                    >
                      <Row type="flex">
                        <Col style={{ marginRight: 10 }}>
                          <Button
                            onClick={() => {
                              history.push(
                                "/hesaplar/" +
                                  getAccountURLByValue(accountType) +
                                  "/" +
                                  accountId +
                                  "/islemler"
                              );
                            }}
                            type="primary"
                            icon="transaction"
                          >
                            İşlemleri Görüntüle
                          </Button>
                        </Col>
                      </Row>
                      <Row type="flex">
                        <Col style={{ marginRight: 10 }}>
                          <Button
                            onClick={this.deleteAccount}
                            type="danger"
                            icon="delete"
                            disabled={isEdit}
                          ></Button>
                        </Col>

                        <Col style={{ marginRight: 10 }}>
                          <Button
                            onClick={() => {
                              this.setState({ isEdit: !this.state.isEdit });
                              resetForm();
                            }}
                            type="primary"
                            icon={isEdit ? "eye" : "edit"}
                          ></Button>
                        </Col>

                        {transactionTypeObject && (
                          <Col>
                            <Dropdown
                              disabled={isEdit}
                              overlay={() => (
                                <Menu
                                  onClick={(e: any) => {
                                    history.push("/islemler/" + e.key + "/yeni", {
                                      defaultAccount: accountId
                                    });
                                  }}
                                >
                                  <Menu.Item key={transactionTypeObject.urlName}>
                                    <Icon type="file-add" />
                                    {transactionTypeObject.name} Oluştur
                                  </Menu.Item>
                                </Menu>
                              )}
                            >
                              <Button icon="ellipsis" type="primary"></Button>
                            </Dropdown>
                          </Col>
                        )}
                      </Row>
                    </Row>
                  )}
                  {!accountType && (
                    <Row>
                      <Col>
                        <Form.Item
                          colon={false}
                          label="Hesap Tipi"
                          validateStatus={
                            errors.accountType && touched.accountType ? "error" : "success"
                          }
                          help={
                            errors.accountType && touched.accountType ? errors.accountType : null
                          }
                        >
                          <Select
                            id="accountType"
                            onChange={value => setFieldValue("accountType", value)}
                            style={{ width: "100%", fontFamily: "Arcon,Harabara !important" }}
                            value={values.accountType}
                            disabled={!isEdit}
                          >
                            {accountTypes.map(type => (
                              <Select.Option key={type.value}>{type.name}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <Form.Item
                        colon={false}
                        label="Hesap Kodu"
                        validateStatus={
                          errors.accountCode && touched.accountCode ? "error" : "success"
                        }
                        help={errors.accountCode && touched.accountCode ? errors.accountCode : null}
                      >
                        <InputField
                          name="accountCode"
                          type="text"
                          addonBefore={`${
                            values.accountType ? getAccountNameByValue(values.accountType) : ""
                          }.`}
                          value={values.accountCode}
                          onChange={handleChange}
                          disabled={!isEdit}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    {values.accountType &&
                      this.renderAdditionalFields({
                        values,
                        errors,
                        touched,
                        handleChange,
                        setFieldValue
                      })}
                  </Row>
                </Form>
              </StyledCard>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }
}

const dispatchToProps = dispatch => {
  return {
    createAccount: params => dispatch(accountActions.createAccount(params)),
    deleteAccount: params => dispatch(accountActions.deleteAccount(params)),
    updateAccount: params => dispatch(accountActions.updateAccount(params)),
    getAccount: params => dispatch(accountActions.getAccount(params)),
    setBreadcrumbRoutes: routes => dispatch(breadcrumbActions.setBreadcrumbRoutes(routes))
  };
};
export default connect(null, dispatchToProps)(AccountCEV);

const accountValidation = yup.object().shape({
  accountName: yup
    .string()
    .when(["accountType", "personType"], (accountType, personType, schema) => {
      if (
        accountType !== "employee" &&
        (accountType === "customer" || accountType === "supplier") &&
        personType !== "person"
      ) {
        return schema.required("Hesap ismi zorunlu alandır!");
      } else return schema;
    }),
  firstName: yup.string().when(["accountType", "personType"], (accountType, personType, schema) => {
    if (
      accountType === "employee" ||
      ((accountType === "customer" || accountType === "supplier") && personType === "person")
    ) {
      return schema.required("İsim zorunlu alandır!");
    } else return schema;
  }),
  lastName: yup.string().when(["accountType", "personType"], (accountType, personType, schema) => {
    if (
      accountType === "employee" ||
      ((accountType === "customer" || accountType === "supplier") && personType === "person")
    ) {
      return schema.required("Soyisim zorunlu alandır!");
    } else return schema;
  }),
  personType: yup.string().when("accountType", (accountType, schema) => {
    if (accountType === "customer" || accountType === "supplier") {
      return schema.required("Bu alan zorunludur!");
    } else return schema;
  }),
  accountCode: yup.string().required("Hesap kodu zorunlu alandır!"),
  accountType: yup.string().required("Hesap tipi zorunlu alandır!")
});

const StyledCard = styled(Card)`
  box-shadow: 1px 4px 16px 2px ${props => props.theme.colors.shadowColor};
`;

const AdditionalRows = styled(Row)`
  background: #fafafa;
  padding: 0 20px;
  margin-bottom: 10px;
  border-radius: 4px;
`;

const InputField = styled(Input)`
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
  }
  color: rgba(0, 0, 0, 0.65) !important;
`;

const SelectField = styled(Select)`
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
  }
  color: rgba(0, 0, 0, 0.65) !important;
`;
