import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Card, Button } from "antd";
import Accounts from "../_defaultComponents/AccountingComponents/Accounts";
import history from "../../_core/history";

interface IProps {
  accountId: string;
}

interface IState {}

class AccountsPage extends Component<IProps, IState> {
  render() {
    return (
      <Row type="flex" justify="center">
        <Col span={22}>
          <StyledRow type="flex" justify="space-between">
            <Col>
              <StyledTitle>İşlemler</StyledTitle>
            </Col>
            <Col>
              <Row type="flex" justify="space-between" gutter={8}>
                <Col>
                  <StyledButton
                    type="primary"
                    icon="plus"
                    onClick={() => {
                      const routing = history.location.pathname.replace("/islemler", "");
                      history.push(routing);
                    }}
                  >
                    Hesaba Dön
                  </StyledButton>
                </Col>
                <Col>
                  <StyledButton
                    type="primary"
                    icon="plus"
                    onClick={() => {
                      history.push("/hesaplar/yeni");
                    }}
                  >
                    Hesap Ekle
                  </StyledButton>
                </Col>
              </Row>
            </Col>
          </StyledRow>
          <StyledCard bordered>
            <Accounts accountId={this.props.accountId} />
          </StyledCard>
        </Col>
      </Row>
    );
  }
}

export default AccountsPage;

const StyledCard = styled(Card)`
  border-radius: 10px !important;
  .ant-card-body {
    padding: 0;
  }
`;

const StyledButton: any = styled(Button)`
  @media (max-width: 400px) {
    padding: 0 5px !important;
  }
`;

const StyledTitle = styled.h2`
  color: ${props => props.theme.colors.darkGray};
  font-size: calc(18px + 0.4vw);
  margin: 0;
`;

const StyledRow: any = styled(Row).attrs({
  type: "flex",
  justify: "space-between"
})`
  padding: 20px 0;
  @media (max-width: 400px) {
    margin-bottom: 15px;
  }
`;
