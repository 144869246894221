import accountTypes from "../constants/accountTypes";
import { AccountCategoryType } from "../types/generalTypes";

/*export const getAccountInfoByValue = (accountType: string): AccountCategoryType | undefined =>
  accountTypes.find(account => account.value === accountType);*/

export const getAccountURLByValue = (accountType: string): string | undefined =>
  (accountTypes.find(account => account.value === accountType) || ({} as AccountCategoryType))!
    .urlName;

export const getAccountNameByValue = (accountType: string): string | undefined =>
  (accountTypes.find(account => account.value === accountType) || ({} as AccountCategoryType))!
    .name;

export const getAccountNameByURL = (accountURL: string): string | undefined =>
  (accountTypes.find(account => account.urlName === accountURL) || ({} as AccountCategoryType))!
    .name;

export const getAccountValueByURL = (accountURL: string): string | undefined =>
  (accountTypes.find(account => account.urlName === accountURL) || ({} as AccountCategoryType))!
    .value;

export const getAccountCodeConverted = (accountCode: string): string | undefined => {
  const accountTypeText = (accountTypes.find(account => accountCode.startsWith(account.value)) ||
    ({} as AccountCategoryType))!;
  const splitAccountCode = accountCode.split(accountTypeText.value);
  return accountTypeText && accountTypeText.name + splitAccountCode[1];
};

export const getAccountUrlByAccountCode = (accountCode: string): string | undefined =>
  (accountTypes.find(account => accountCode.startsWith(account.value)) ||
    ({} as AccountCategoryType))!.urlName;

export const getTransactionTypesOfAccountTypeByValue = (accountType: string): string | undefined =>
  (accountTypes.find(account => account.value === accountType) || ({} as AccountCategoryType))!
    .transactionTypes;
