import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Layout, Breadcrumb, Icon, Button } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";

import Sider from "./_defaultComponents/Sider/Sider";
import { UserType } from "../types/generalTypes";
import history from "../_core/history";
// import siderBg from "../public/images/sider-bg.jpg";

interface IProps {
  user: UserType;
  breadcrumbRoutes: Route[];
  children: React.ReactNode;
}

interface IState {
  collapsed: boolean;
}

class Main extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { collapsed: false };
  }

  toggleCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  itemRender = (route, params, routes, paths) => {
    return (
      <Button type="link" size="small" onClick={() => history.push(route.path)}>
        {route.breadcrumbName}
      </Button>
    );
  };

  render() {
    const { collapsed } = this.state;
    const loggedIn = this.props.user.loggedIn;
    return (
      <StyledLayout style={{ minHeight: window.innerHeight }}>
        {loggedIn && (
          <>
            <Layout.Sider
              collapsible
              collapsed={collapsed}
              onCollapse={this.toggleCollapse}
              width={250}
              theme="light"
              // trigger={
              //   <Icon style={{ color: "white" }} type={collapsed ? "double-right" : "double-left"} />
              // }
              // style={{
              //   background: `linear-gradient(rgba(255, 255, 255, 0.97) 50%, rgba(255, 255, 255, 0.98) 84%), url(${siderBg})`
              // }}
              trigger={null}
            >
              <Sider collapsed={collapsed} currentPathname={history.location.pathname} />
            </Layout.Sider>

            <LayoutHeader collapsed={collapsed} onClick={this.toggleCollapse}>
              <Icon type={collapsed ? "double-right" : "double-left"} />
            </LayoutHeader>
          </>
        )}
        <Layout>
          <LayoutContent loggedIn={loggedIn} collapsed={collapsed}>
            {loggedIn && this.props.breadcrumbRoutes.length > 1 && (
              <Breadcrumb
                style={{
                  paddingBottom: 20,
                  paddingLeft: "3.3%",
                  borderBottom: "1px solid #b4b5b8"
                }}
                separator={<Icon type="swap-right" />}
                routes={this.props.breadcrumbRoutes}
                itemRender={this.itemRender}
              />
            )}
            {this.props.children}
          </LayoutContent>
        </Layout>
      </StyledLayout>
    );
  }
}

const stateToProps = state => ({
  user: state.user,
  breadcrumbRoutes: state.breadcrumb.routes
});

export default connect(stateToProps, null)(Main);

interface LayoutProps {
  collapsed: boolean;
  loggedIn: boolean;
}

const LayoutHeader = styled(Layout.Header)<{ collapsed: boolean }>`
  background: rgb(250, 250, 250);
  display: flex;
  border-radius: 50%;
  position: absolute;
  left: ${props => (props.collapsed ? 60 : 230)}px;
  height: 40px;
  width: 40px;
  padding: 0;
  top: 8px;
  transition: left 0.2s;
  cursor: pointer;
  & > i {
    margin: auto;
  }
`;

const LayoutContent = styled(Layout.Content)<LayoutProps>`
  align-items: flex-start;
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  justify-content: space-around;
  @media (max-width: 450px) {
    display: ${props => (props.collapsed || !props.loggedIn ? "block" : "none")};
  }
  @media (max-width: 320px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const StyledLayout = styled(Layout)`
  .ant-layout-sider-trigger {
    background-color: ${props => props.theme.colors.primaryGreen};
  }
`;
