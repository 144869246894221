import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Statistic, Icon, Avatar, Timeline, Badge } from "antd";
import moment from "moment";
import "moment/min/locales";

import history from "../../_core/history";
import * as accountingActions from "../../store/actions/accountingActions";
import * as breadcrumbActions from "../../store/actions/breadcrumbActions";
import { getTransactionURLByValue } from "../../util/transactionHelper";
import { getAccountCodeConverted } from "../../util/accountHelper";

moment.locale("tr");
interface IProps {
  getStats: any;
  resetBreadcrumbRoutes: () => void;
}

class MainPage extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }

  componentDidMount() {
    this.getStatistics();
    this.props.resetBreadcrumbRoutes();
  }

  getStatistics = () => {
    this.props.getStats().then(result => {
      const user = result.value.data;
      this.setState({ user });
    });
  };

  render() {
    const { user } = this.state;
    const { bills = [] } = user;

    return (
      <React.Fragment>
        <Row align="middle" justify="center" type="flex" style={{ margin: 40, fontSize: 25 }}>
          <Avatar shape="square" icon="user" style={{ background: "#4fb087", marginRight: 10 }} />
          {user.fullName}
        </Row>
        <Row justify="space-between" type="flex" style={{ margin: "0 4%" }}>
          <Col span={11}>
            <Card>
              <Statistic
                title="Hesap Sayısı"
                value={user.accountCount}
                prefix={<Icon type="audit" />}
              ></Statistic>
            </Card>
          </Col>
          <Col span={11}>
            <Card>
              <Statistic
                title="İşlem Sayısı"
                value={user.transactionCount}
                prefix={<Icon type="transaction" />}
              ></Statistic>
            </Card>
          </Col>
        </Row>
        <Row justify="center" type="flex" style={{ marginTop: 20 }}>
          <Col span={22}>
            <Card
              title="Yakınlardaki İşlemler"
              // bodyStyle={{ display: "flex", justifyContent: "center" }}
            >
              <Row type="flex" justify="space-around" style={{ borderBottom: "1px solid #4fb087" }}>
                <Col>
                  <Badge
                    count={user.billCount}
                    overflowCount={99}
                    style={{ background: "#4fb087" }}
                  >
                    <div style={{ padding: 10 }}>Faturalar</div>
                  </Badge>
                </Col>
                <Col>
                  <Badge
                    count={user.expenseCount}
                    overflowCount={99}
                    style={{ background: "#4fb087" }}
                  >
                    <div style={{ padding: 10 }}>Gider Belgeri</div>
                  </Badge>
                </Col>
              </Row>
              <Row justify="center" type="flex">
                <Col span={14}>
                  <Timeline
                    mode="alternate"
                    style={{ overflow: "auto", paddingTop: 30, maxHeight: "45vh" }}
                  >
                    {!bills.length ? (
                      <Timeline.Item dot={<Icon type="close-circle"></Icon>} color={"red"}>
                        Hiç bir işleminiz bulunmadı
                      </Timeline.Item>
                    ) : (
                      bills.map((bill, i) => {
                        return (
                          <Timeline.Item
                            dot={
                              moment(bill.expiryDate).isSameOrBefore(new Date()) && (
                                <Icon type="check"></Icon>
                              )
                            }
                            position={bill.transactionType === "bill" ? "right" : "left"}
                            color={
                              moment(bill.expiryDate).isSameOrBefore(new Date()) ? "green" : "blue"
                            }
                          >
                            <Card
                              hoverable
                              title={moment(bill.expiryDate).format("LL")}
                              onClick={() => {
                                history.push(
                                  `/islemler/${getTransactionURLByValue(bill.transactionType)}/${
                                    bill._id
                                  }`
                                );
                              }}
                            >
                              <p>{`Not: ${bill.note}`}</p>
                              <p>
                                {`Tutar: ${
                                  bill.entries[0][
                                    bill.transactionType === "bill" ? "credit" : "debit"
                                  ]
                                } ${bill.currency}`}
                              </p>
                              <p>
                                {`${
                                  bill.transactionType === "bill" ? "Müşteri" : "Tedarikçi"
                                }: ${getAccountCodeConverted(bill.entries[0].accountCode)}`}
                              </p>
                            </Card>
                          </Timeline.Item>
                        );
                      })
                    )}
                  </Timeline>
                </Col>
              </Row>
              {/* <Col span={4} style={{ textAlign: "right" }}>
                <Badge
                  count={user.expenseCount}
                  overflowCount={99}
                  style={{ background: "#4fb087" }}
                >
                  <div style={{ padding: 10 }}>Gider Belgeri</div>
                </Badge>
              </Col> */}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const dispatchToProps = dispatch => {
  return {
    getStats: params => dispatch(accountingActions.getStats(params)),
    resetBreadcrumbRoutes: () => dispatch(breadcrumbActions.resetBreadcrumbRoutes())
  };
};

const stateToProps = state => {
  return {};
};
export default connect(stateToProps, dispatchToProps)(MainPage);
