import React, { Component } from "react";
import { connect } from "react-redux";

import history from "../../_core/history";
import styled from "styled-components";
import AccountList from "./AccountList";
import accountTypes from "../../constants/accountTypes";
import * as accountActions from "../../store/actions/accountActions";
import * as breadcrumbActions from "../../store/actions/breadcrumbActions";
import { getAccountNameByValue, getAccountURLByValue } from "../../util/accountHelper";

import { Row, Col, Button } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";

type account = {
  accountCode: string;
  accountName: string;
  email: string;
  phoneNumber: string;
  _id?: string;
  isDeleted: boolean;
};

interface IProps {
  getAccountList: (params: { accountType?: string }) => any;
  setBreadcrumbRoutes: (routes: Route[]) => any;
  accountType?: string;
}

interface IState {
  accountList: Array<account> | [];
  accountListFiltered: Array<account> | [];
}

class AccountDetailsPage extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      accountList: [],
      accountListFiltered: []
    };
  }

  componentDidMount = async () => {
    this.setBreadcrumb();
    this.fetchAccountList();
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.accountType !== this.props.accountType) {
      this.setBreadcrumb();
      this.fetchAccountList();
    }
  };

  setBreadcrumb = () => {
    const { accountType } = this.props;
    const routes: Route[] = [
      {
        path: "/hesaplar",
        breadcrumbName: "Hesaplar"
      }
    ];

    routes.push({
      path: "/hesaplar" + (accountType ? `/${getAccountURLByValue(accountType)}` : ""),
      breadcrumbName: accountType ? getAccountNameByValue(accountType) || "" : "Tümü",
      children: [
        { path: "/hesaplar", breadcrumbName: "Tümü" },
        ...accountTypes.map(account => ({
          path: "/hesaplar/" + account.urlName,
          breadcrumbName: account.name
        }))
      ]
    });

    this.props.setBreadcrumbRoutes(routes);
  };

  fetchAccountList = async () => {
    const { accountType } = this.props;

    try {
      const accountList = (await this.props.getAccountList({ accountType })).action.payload.data;
      this.setState({
        accountList,
        accountListFiltered: accountList.filter(account => !account.isDeleted)
      });
    } catch (err) {
      console.log(err);
    }
  };

  filterList = filterForDelete => {
    if (filterForDelete) {
      const accountListFiltered = this.state.accountList.filter(account => !account.isDeleted);
      this.setState({ accountListFiltered });
    } else {
      const accountListFiltered = this.state.accountList;
      this.setState({ accountListFiltered });
    }
  };

  render() {
    const { accountType } = this.props;
    const { accountListFiltered } = this.state;

    return (
      <Row type="flex" justify="center">
        <Col span={22}>
          <StyledRow>
            <Col>
              <StyledTitle>
                {accountType ? getAccountNameByValue(accountType) + " Hesapları" : "Hesaplar"}
              </StyledTitle>
            </Col>
            <Col>
              <StyledButton
                type="primary"
                icon="plus"
                onClick={() => {
                  history.push(`${history.location.pathname}/yeni`);
                }}
              >
                {accountType ? getAccountNameByValue(accountType) + " Hesabı " : "Hesap"} Ekle
              </StyledButton>
            </Col>
          </StyledRow>
          <AccountList
            filterList={this.filterList}
            dataSource={accountListFiltered}
            accountType={accountType || ""}
          />
        </Col>
      </Row>
    );
  }
}

const dispatchToProps = dispatch => {
  return {
    getAccountList: params => dispatch(accountActions.getAccountList(params)),
    setBreadcrumbRoutes: routes => dispatch(breadcrumbActions.setBreadcrumbRoutes(routes))
  };
};

export default connect(null, dispatchToProps)(AccountDetailsPage);

const StyledButton: any = styled(Button)`
  @media (max-width: 400px) {
    padding: 0 5px !important;
  }
`;

const StyledTitle = styled.h2`
  color: ${props => props.theme.colors.darkGray};
  font-size: calc(18px + 0.4vw);
  margin: 0;
`;

const StyledRow: any = styled(Row).attrs({
  type: "flex",
  justify: "space-between"
})`
  padding: 20px 0;
  @media (max-width: 400px) {
    margin-bottom: 15px;
  }
`;
