import { ModalFuncProps } from "antd/lib/modal";

export const defErrorModalProps: ModalFuncProps = {
  title: "Hata",
  content: "Hata oluştu.",
  okText: "Tamam",
  maskClosable: true
};

export const defConfirmModalProps: ModalFuncProps = {
  title: "Onay",
  content: "Emin misiniz?",
  okText: "Tamam",
  cancelText: "Vazgeç",
  keyboard: false,
  autoFocusButton: null
};

export const defInfoModalProps: ModalFuncProps = {
  title: "Bilgilendirme",
  content: "Bilgilendirme...",
  okText: "Tamam",
  maskClosable: true
};

export const defWarningModalProps: ModalFuncProps = {
  title: "Uyarı",
  content: "Uyarı...",
  okText: "Tamam",
  maskClosable: true
};

export const defSuccessModalProps: ModalFuncProps = {
  title: "Başarılı",
  content: "Başarılı...",
  okText: "Tamam",
  maskClosable: true
};
