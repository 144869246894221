import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import Transaction from "../_defaultComponents/AccountingComponents/Transaction";
import history from "../../_core/history";
import transactionTypes from "../../constants/transactionTypes";
import * as breadcrumbActions from "../../store/actions/breadcrumbActions";
import {
  getTransactionNameByValue,
  getTransactionTypeObjByValue,
  getTransactionURLByValue
} from "../../util/transactionHelper";

import { Row, Col, Button } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";

interface IProps {
  transactionType?: string;
  defaultAccount?: string;
  setBreadcrumbRoutes: (routes: Route[]) => any;
}

interface IState {}

class TransactionsPage extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount(): void {
    this.setBreadcrumb();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (prevProps.transactionType !== this.props.transactionType) {
      this.setBreadcrumb();
    }
  }

  setBreadcrumb = () => {
    const { transactionType } = this.props;
    const routes: Route[] = [
      {
        path: "/islemler",
        breadcrumbName: "İşlemler"
      },
      {
        path: "/islemler/" + getTransactionURLByValue(transactionType || "transaction"),
        breadcrumbName: getTransactionNameByValue(transactionType || "transaction") || "",
        children: transactionTypes.map(transaction => {
          return { path: "/islemler/" + transaction.urlName, breadcrumbName: transaction.name };
        })
      }
    ];
    this.props.setBreadcrumbRoutes(routes);
  };

  render() {
    const { transactionType } = this.props;

    const transactionTypeObj = getTransactionTypeObjByValue(transactionType);

    /*if (id || transactionType === "yeni") {
      return (
        <TransactionDetailPage
          id={id === "yeni" || transactionType === "yeni" ? "" : id}
          defaultAccount={defaultAccount}
          transactionType={transactionTypeObj.value}
        />
      );
    }*/
    return (
      <Row type="flex" justify="center">
        <Col span={22}>
          <StyledRow>
            <Col>
              <StyledTitle>{transactionTypeObj.name} Listesi</StyledTitle>
            </Col>
            <Col>
              <StyledButton
                type="primary"
                icon="file-add"
                onClick={() => history.push("/islemler/" + transactionTypeObj.urlName + "/yeni")}
              >
                {transactionTypeObj.name} Ekle
              </StyledButton>
            </Col>
          </StyledRow>
          {/* <StyledCard bordered> */}
          <Transaction transactionType={transactionTypeObj.value} />
          {/* </StyledCard> */}
        </Col>
      </Row>
    );
  }
}

const dispatchToProps = dispatch => ({
  setBreadcrumbRoutes: routes => dispatch(breadcrumbActions.setBreadcrumbRoutes(routes))
});

export default connect(null, dispatchToProps)(TransactionsPage);

const StyledButton: any = styled(Button)`
  @media (max-width: 400px) {
    padding: 0 5px !important;
  }
`;

const StyledTitle = styled.h2`
  color: ${props => props.theme.colors.darkGray};
  font-size: calc(18px + 0.4vw);
  margin: 0;
`;

const StyledRow: any = styled(Row).attrs({
  type: "flex",
  justify: "space-between"
})`
  padding: 20px 0;
  @media (max-width: 400px) {
    margin-bottom: 15px;
  }
`;
