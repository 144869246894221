import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Select, Icon } from "antd";

import * as accountActions from "../../../store/actions/accountActions";
import { getAccountCodeConverted } from "../../../util/accountHelper";

const Option = Select.Option;

interface IProps {
  isEdit?: boolean;
  value: string;
  onSelectedAccount: any;
  createAccount: any;
  getAllAccount: any;
  accountTypes: string;
}

interface IState {
  accountList: any;
  selectedAccount: any;
}

class AccountSelectBox extends Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      accountList: [],
      selectedAccount: {}
    };
  }

  componentDidMount = async () => {
    const { accountTypes } = this.props;
    const accountList = (await this.props.getAllAccount({ accountType: accountTypes })).action
      .payload.data;
    await this.setState({ accountList });
  };

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (this.state.accountList !== prevState.accountList || prevProps.value !== this.props.value) {
      const selectedAccount = this.state.accountList.find(
        account => account._id === this.props.value
      );
      this.setState({ selectedAccount: selectedAccount || {} });
    }
  }

  handleChange = value => {
    const { accountList } = this.state;
    const selectedAccount = accountList.find(account => account._id === value);
    this.setState({ selectedAccount });
    const unitPayment = accountList.find(account => account._id === value).unitPayment;
    this.props.onSelectedAccount({ value, unitPayment });
  };

  render() {
    const { value, isEdit, accountTypes } = this.props;
    const { accountList, selectedAccount } = this.state;
    return (
      <React.Fragment>
        <Select
          placeholder="Hesap Adı"
          onChange={this.handleChange}
          disabled={!isEdit}
          style={{
            width: "100%",
            fontFamily: "Arcon,Harabara !important",
            color: "rgba(0, 0, 0, 0.65)"
          }}
          showSearch
          value={value}
        >
          {accountList.map(item => {
            return (
              <Option key={item._id} value={item._id}>
                {getAccountCodeConverted(item.accountCode)}
              </Option>
            );
          })}
        </Select>
        {(accountTypes === "customer" || accountTypes === "supplier") && (
          <Row type="flex" style={{ backgroundColor: "#eafff8", fontSize: "small" }}>
            <Col xs={22} md={8} style={{ marginLeft: 10, fontWeight: "bold" }}>
              <Icon type="info-circle" /> Hesap Bilgileri
            </Col>
            <Col xs={22} md={8}>
              Hesap Adı: {selectedAccount.accountName || "-"}
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

const stateToProps = state => {
  return {};
};

const dispatchToProps = dispatch => {
  return {
    getAllAccount: params => dispatch(accountActions.getAllAccount(params)),
    createAccount: params => dispatch(accountActions.createAccount(params))
  };
};
export default connect(stateToProps, dispatchToProps)(AccountSelectBox);
