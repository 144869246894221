import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import * as accountingActions from "../../../store/actions/accountingActions";
import { Input, DatePicker, Row, Col, Button, Tabs, Calendar, Switch, Card, Select } from "antd";
import history from "../../../_core/history";
import TransactionList from "../../AccountingPage/TransactionList";
import BillList from "../../AccountingPage/BillList";

const dateFormat = "DD/MM/YYYY";

interface IProps {
  getTransactions: any;
  transactionType: string;
}

interface IState {
  transactionList: Array<object>;
  hasMore: boolean;
  filterStartDate: string;
  filterEndDate: string;
  filterSearchText: string;
  selectedDate: any;
  switchValue: boolean;
  selectedKeys: Array<string>;
}

const styles = {
  lineSpace: { marginRight: 5 }
};

class TransactionPage extends Component<IProps, IState> {
  private iScroll: any;
  private sDate: any;
  private eDate: any;
  private sInput: any;
  constructor(props) {
    super(props);
    this.state = {
      transactionList: [],
      hasMore: true,
      filterEndDate: "",
      filterStartDate: "",
      filterSearchText: "",
      selectedDate: [],
      switchValue: false,
      selectedKeys: ["transaction", "bill", "expenseDocument"]
    };
  }

  doSearch(page) {
    const { transactionType } = this.props;
    const { filterStartDate, filterEndDate, filterSearchText } = this.state;

    page = page * 50;
    this.props
      .getTransactions({
        startDate: filterStartDate,
        endDate: filterEndDate,
        searchText: filterSearchText,
        skip: page,
        limit: 50,
        transactionType: transactionType === "transaction" ? "" : transactionType
      })
      .then(result => {
        const data = (result.value && result.value.data) || [];
        const oldTransactionList = this.state.transactionList;
        const newTransactionList = [...oldTransactionList, ...data];
        if (data.length === 0) this.setState({ hasMore: false });
        this.setState({ transactionList: newTransactionList });
      });
  }

  clearListAndDoSearch() {
    this.iScroll.pageLoaded = 0;
    this.setState({ transactionList: [], hasMore: true }, () => {
      this.doSearch(0);
    });
  }

  componentDidMount = async () => {
    await this.doSearch(0);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    const { transactionType } = this.props;
    if (prevProps.transactionType !== transactionType) {
      await this.clearListAndDoSearch();
      this.setState({ selectedDate: [] });
    }
  };

  renderTransactionList = (dateTransactionList, selected, right, date) => {
    const { transactionType } = this.props;

    const routing =
      transactionType === "transaction"
        ? "/islemler/"
        : transactionType === "bill"
        ? "/faturalar/"
        : "/gider-belgeleri/";
    return (
      <DateContainer>
        <DateText>{date}</DateText>
        <UlList selected={selected} hasTransaction={dateTransactionList.length} right={right}>
          {selected && (
            <ListTitle>
              {transactionType === "transaction" ? "Mahsup Fişleri" : "Faturalar"}
            </ListTitle>
          )}
          {dateTransactionList.map(transaction => (
            <ListItem
              selected={selected}
              onClick={selected ? event => history.push(routing + transaction._id) : () => {}}
            >
              {transaction.note}
            </ListItem>
          ))}
        </UlList>
      </DateContainer>
    );
  };

  render() {
    const { transactionType } = this.props;

    const {
      transactionList = [],
      hasMore = true,
      selectedDate = [],
      switchValue,
      selectedKeys
    } = this.state;

    return (
      <Row type="flex" justify="center" gutter={8}>
        <Col span={24}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Liste Görünümü" key="1">
              <Row style={{ marginBottom: 20 }} type="flex" justify="space-between">
                <Col span={20}>
                  <Row type="flex">
                    <Col style={styles.lineSpace}>
                      <DatePicker
                        placeholder="Başlangıç Tarihi"
                        ref={sDate => {
                          this.sDate = sDate;
                        }}
                        onChange={date => {
                          this.setState({
                            filterStartDate: date !== null ? date.toISOString() : ""
                          });
                        }}
                        format={dateFormat}
                      />
                    </Col>
                    <Col style={styles.lineSpace}>
                      <DatePicker
                        placeholder="Bitiş Tarihi"
                        ref={eDate => {
                          this.eDate = eDate;
                        }}
                        onChange={date => {
                          this.setState({ filterEndDate: date !== null ? date.toISOString() : "" });
                        }}
                        format={dateFormat}
                      />
                    </Col>
                    <Col style={styles.lineSpace}>
                      <Input
                        ref={sInput => {
                          this.sInput = sInput;
                        }}
                        onKeyPress={e => {
                          if (e.key === "Enter") {
                            this.clearListAndDoSearch();
                          }
                        }}
                        onChange={value => {
                          this.setState({ filterSearchText: value.target.value });
                        }}
                        placeholder="Ara"
                      />
                    </Col>
                    {transactionType === "transaction" && (
                      <Col style={styles.lineSpace}>
                        <Select
                          mode="multiple"
                          style={{ width: "100%", minWidth: 300 }}
                          value={selectedKeys}
                          onChange={value => {
                            if (value.length) {
                              this.setState({ selectedKeys: value });
                            }
                          }}
                        >
                          <Select.Option value="transaction">Mahsup Fişi</Select.Option>
                          <Select.Option value="bill">Fatura</Select.Option>
                          <Select.Option value="expenseDocument">Gider Belgesi</Select.Option>
                        </Select>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col span={4}>
                  <Row type="flex" justify="end">
                    <Button
                      style={styles.lineSpace}
                      onClick={e => {
                        this.iScroll.pageLoaded = 0;
                        this.sDate.picker.clearSelection(e);
                        this.eDate.picker.clearSelection(e);
                        this.sInput.state.value = "";
                        this.setState(
                          {
                            filterEndDate: "",
                            filterStartDate: "",
                            filterSearchText: "",
                            transactionList: [],
                            hasMore: true,
                            selectedKeys: ["transaction", "bill", "expenseDocument"]
                          },
                          () => {
                            this.doSearch(0);
                          }
                        );
                      }}
                    >
                      Temizle
                    </Button>
                    <Button
                      onClick={() => {
                        this.clearListAndDoSearch();
                      }}
                    >
                      Filtrele
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Card bodyStyle={{ padding: 0 }} style={{ height: "60vh" }}>
                    <InfiniteScroll
                      ref={iScroll => {
                        this.iScroll = iScroll;
                      }}
                      initialLoad={false}
                      pageStart={0}
                      loadMore={page => this.doSearch(page)}
                      hasMore={hasMore}
                    >
                      {transactionType === "transaction" ? (
                        <TransactionList
                          transactionList={transactionList.filter((tr: any) =>
                            selectedKeys.includes(tr.transactionType)
                          )}
                        />
                      ) : (
                        <BillList
                          transactionType={transactionType}
                          billList={transactionList}
                        ></BillList>
                      )}
                    </InfiniteScroll>
                  </Card>
                </Col>
              </Row>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Takvim Görünümü" key="2">
              {transactionType !== "transaction" && (
                <Row type="flex" align="middle" style={{ padding: "0 15px" }}>
                  <BorderedCol
                    onClick={() => {
                      this.setState({ switchValue: false, selectedDate: [] });
                    }}
                    selected={!switchValue}
                  >
                    Düzenlenme Tarihi
                  </BorderedCol>

                  <Col>
                    <CustomSwitch
                      checked={switchValue}
                      onChange={() => {
                        this.setState({ switchValue: !switchValue, selectedDate: [] });
                      }}
                    />
                  </Col>
                  <BorderedCol
                    onClick={() => {
                      this.setState({ switchValue: true, selectedDate: [] });
                    }}
                    selected={switchValue}
                  >
                    Vade Tarihi
                  </BorderedCol>
                </Row>
              )}

              <Calendar
                dateFullCellRender={value => {
                  const selected = selectedDate[0] === value.date();
                  const right = value.day() === 6 || value.day() === 5;
                  const dateTransactionList = transactionList.filter((transaction: any) => {
                    const transactionDate = new Date(
                      transaction[switchValue ? "expiryDate" : "date"]
                    );
                    return (
                      transactionDate.getDate() === value.date() &&
                      transactionDate.getFullYear() === value.year() &&
                      transactionDate.getMonth() === value.month()
                    );
                  });
                  return this.renderTransactionList(
                    dateTransactionList,
                    selected,
                    right,
                    value.date()
                  );
                }}
                monthCellRender={value => {
                  const selected = selectedDate[1] === value.month();
                  const dateTransactionList = transactionList.filter((transaction: any) => {
                    const transactionDate = new Date(
                      transaction[switchValue ? "expiryDate" : "date"]
                    );
                    return (
                      transactionDate.getMonth() === value.month() &&
                      transactionDate.getFullYear() === value.year()
                    );
                  });
                  return this.renderTransactionList(
                    dateTransactionList,
                    selected,
                    0,
                    value.month()
                  );
                }}
                onPanelChange={() => {
                  this.setState({ selectedDate: [] });
                }}
                onSelect={(value: any) => {
                  this.setState({ selectedDate: [value.date(), value.month()] });
                }}
              />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
    );
  }
}

const DateContainer = styled.div`
  border-top: 1px solid gainsboro;
  height: 70px;
`;
const DateText = styled.div`
  text-align: right;
  font-size: 12px;
`;

const UlList = styled.ul<{ selected: boolean; hasTransaction: boolean; right: string }>`
  padding: ${p => (p.selected ? 0 : 0)}px;
  margin: 0px;
  right: ${p => p.right && "0px"};
  list-style: none;
  position: ${p => (p.selected ? "absolute" : "relative")};
  background: ${p => (p.selected ? "white" : "transparent")};
  z-index: ${p => (p.selected ? 2 : 1)};
  font-size: ${p => (p.selected ? 17 : 14)}px;
  border-radius: 5px;
  transition: all 0.2s !important;
  cursor: default;
  display: ${p => (p.hasTransaction ? "block" : "none")};
  color: #535556;
  width: ${p => (p.selected ? "max-content" : "unset")};
  box-shadow: ${p => p.selected && "1px 4px 5px 2px #d2d2d2"};
  min-width: ${p => p.selected && 200}px;
  max-width: 350px;
  /* height: 45px; */
  overflow: auto;
`;

const ListItem = styled.li<{ selected: boolean }>`
  padding: ${props => (props.selected ? "10px" : "0px 10px")};
  background: white;
  cursor: pointer;
  margin: ${props => (props.selected ? "0px" : "5px 0px")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 5px;
  font-size: 13px;
  &:before {
    content: "◉ ";
    color: ${props => props.theme.colors.logoColor};
  }
  &:hover {
    background: ${props => props.selected && `${props.theme.colors.logoColor}42`};
  }
`;

const ListTitle = styled.p`
  padding: 10px;
  margin: 0px;
  text-align: center;
  border-bottom: 1px solid gainsboro;
`;

const BorderedCol = styled(Row)<{ selected: boolean }>`
  border: 1px solid gainsboro;
  border-radius: 4px;
  padding: 4px 10px;
  cursor: pointer;
  border-color: ${props => (props.selected ? props.theme.colors.logoColor : "gainsboro")};
  color: ${props => props.selected && props.theme.colors.logoColor};
  &:hover {
    border-color: ${props => props.theme.colors.logoColor};
    color: ${props => props.theme.colors.logoColor};
  }
`;

const CustomSwitch = styled(Switch)`
  background: ${props => props.theme.colors.logoColor} !important;
  margin: 1px 10px !important;
`;

const dispatchToProps = dispatch => {
  return {
    getTransactions: params => dispatch(accountingActions.getTransactions(params))
  };
};

export default connect(null, dispatchToProps)(TransactionPage);
