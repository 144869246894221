import React, { Component } from "react";
import { Table } from "antd";
import moment from "moment";
import history from "../../_core/history";
import { getAccountCodeConverted } from "../../util/accountHelper";
import { getTransactionURLByValue, getTransactionNameByValue } from "../../util/transactionHelper";

const { Column } = Table;

interface IProps {
  transactionList: Array<object>;
}

class TransactionList extends Component<IProps, any> {
  render() {
    const { transactionList = [] } = this.props;
    return (
      <React.Fragment>
        <Table
          scroll={{ y: "54vh" }}
          size="middle"
          dataSource={transactionList}
          pagination={false}
          locale={{ emptyText: "Herhangi bir işlem bulunamadı!" }}
          onRow={(record: any, rowIndex) => {
            return {
              style: { cursor: "pointer" },
              onClick: event => {
                history.push(
                  "/islemler/" + getTransactionURLByValue(record.transactionType) + "/" + record._id
                );
              }
            };
          }}
          expandedRowRender={(record, index, indent, expanded) => {
            const { entries } = record;
            const [firstEntry, ...restEntries] = entries;

            return (
              <div>
                {record.transactionType !== "transaction" ? (
                  <Table dataSource={[firstEntry]} size="middle" pagination={false}>
                    <Column
                      align="left"
                      title={record.transactionType === "bill" ? "Müşteri" : "Tedarikçi"}
                      key="accountCode"
                      dataIndex="accountCode"
                      render={(text, row) => getAccountCodeConverted(text)}
                    />
                    {restEntries.map(item => (
                      <Column
                        align="left"
                        title="Hesap"
                        render={(text, row) => {
                          return getAccountCodeConverted(item.accountCode);
                        }}
                      />
                    ))}
                    <Column
                      align="left"
                      title="Belge Tutarı"
                      key={record.transactionType === "bill" ? "debit" : "credit"}
                      dataIndex={record.transactionType === "bill" ? "debit" : "credit"}
                    />
                    <Column
                      align="left"
                      title="Düzenlenme Tarihi"
                      render={(text, row) => {
                        return moment(record.date).format("DD/MM/YYYY");
                      }}
                    />
                    <Column
                      align="left"
                      title="Vade Durumu"
                      render={(text, row) => {
                        return moment(record.expiryDate).format("DD/MM/YYYY");
                      }}
                    />
                  </Table>
                ) : (
                  <Table dataSource={entries} size="middle" pagination={false}>
                    <Column
                      align="left"
                      title="Hesap Kodu"
                      key="accountCode"
                      dataIndex="accountCode"
                      render={(text, row) => getAccountCodeConverted(text)}
                    />
                    <Column align="left" title="Açıklama" key="note" dataIndex="note" />
                    <Column align="left" title="Alacak" key="credit" dataIndex="credit" />
                    <Column align="left" title="Borç" key="debit" dataIndex="debit" />
                  </Table>
                )}
              </div>
            );
          }}
        >
          <Column
            align="left"
            title="Tip"
            dataIndex="transactionType"
            key="transactionType"
            render={(text, row) => getTransactionNameByValue(text)}
          />
          <Column
            align="left"
            title="Tarih"
            dataIndex="date"
            render={(text, row) => moment(text).format("DD/MM/YYYY")}
            key="date"
          />
          <Column align="left" title="Açıklama" dataIndex="note" key="note" />
        </Table>
      </React.Fragment>
    );
  }
}

export default TransactionList;
