import { Menu, Button, Icon } from "antd";
import styled from "styled-components";
export const HeaderMenu = styled.div`
  display: flex;
  justify-content: flex-end !important;
  height: 45px !important;
  font-size: 16px !important;
  background-color: rgba(255, 255, 255, 0.96) !important;
  box-shadow: rgb(227, 227, 227) 0px -1px 0px 0px inset;
  padding: 0px 20px;
  align-items: center;
  margin-bottom: 30px;
  position: fixed;
  z-index: 1000;
  right: 0;
  left: 0;
  top: 0;
`;

export const MenuButton: any = styled(Button)`
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  font-size: 16px !important;
  color: black !important;
`;

export const SubMenu: any = styled(Menu.SubMenu)`
  & > ul {
    /* background: transparent !important; */
  }
`;

// export const MenuItem: any = styled(Item)`
//   font-size: 15px !important;
//   font-weight: 100;
// `;

export const SubMenuHeader = styled.span`
  font-size: 20px;
  font-weight: 400;
  /* color: ${props => props.theme.colors.midGray}; */
`;

export const StyledIcon = styled(Icon)<{ collapsed: boolean }>`
  font-size: ${p => (p.collapsed ? 24 : 20)}px !important;
  margin-left: ${p => (p.collapsed ? -5 : 0)}px !important;
  transition: all 0.2s !important;
`;
