import React, { Component } from "react";
import { Row, Col, Card } from "antd";
import styled from "styled-components";

import TransactionCEV from "../_defaultComponents/AccountingComponents/TransactionCEV";
import { getTransactionNameByValue } from "../../util/transactionHelper";
import BillCEV from "../_defaultComponents/AccountingComponents/BillCEV";
import history from "../../_core/history";

interface IProps {
  transactionId?: string;
  transactionType?: string;
  defaultAccount?: string;
  isNew: boolean;
}

interface IState {}

class TransactionDetailPage extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount() {
    const state = {};
    history.replace({ ...history.location, state });
  }

  render() {
    const { transactionId, transactionType, defaultAccount, isNew } = this.props;
    return (
      <Row type="flex" justify="center">
        <Col span={22}>
          <StyledCard
            title={transactionType && getTransactionNameByValue(transactionType)}
            style={{ borderRadius: 10, marginTop: 30 }}
            bordered
          >
            {transactionType === "transaction" ? (
              <TransactionCEV transactionId={transactionId} isNew={isNew} />
            ) : (
              <BillCEV
                defaultAccount={defaultAccount}
                transactionId={transactionId}
                type={transactionType || "bill"}
                isNew={isNew}
              />
            )}
          </StyledCard>
        </Col>
      </Row>
    );
  }
}

export default TransactionDetailPage;

const StyledCard = styled(Card)`
  box-shadow: 1px 4px 16px 2px ${props => props.theme.colors.shadowColor};
`;
