import transactionTypes from "../constants/transactionTypes";
import { TransactionCategoryType } from "../types/generalTypes";

export const getTransactionTypeObjByValue = (
  transactionType: string = ""
): TransactionCategoryType =>
  transactionTypes.find(transaction => transaction.value === transactionType) ||
  ({} as TransactionCategoryType);

export const getTransactionURLByValue = (transactionType: string): string | undefined =>
  (transactionTypes.find(transaction => transaction.value === transactionType) ||
    ({} as TransactionCategoryType))!.urlName;

export const getTransactionNameByValue = (transactionType: string): string | undefined =>
  (transactionTypes.find(transaction => transaction.value === transactionType) ||
    ({} as TransactionCategoryType))!.name;

export const getTransactionNameByURL = (transactionUrl: string): string | undefined =>
  (transactionTypes.find(transaction => transaction.urlName === transactionUrl) ||
    ({} as TransactionCategoryType))!.name;

export const getTransactionValueByURL = (transactionUrl: string): string | undefined =>
  (transactionTypes.find(transaction => transaction.urlName === transactionUrl) ||
    ({} as TransactionCategoryType))!.value;
