import baseRequest from "../_core/baseRequest";

const accountService = {
  createAccount: params => baseRequest.post("/account", params),
  getAllAccount: params =>
    baseRequest.get("/account" + (params.accountType ? `?accountType=${params.accountType}` : "")),
  getAccount: params => baseRequest.get(`/account?id=${params.accountId}`),
  updateAccount: params => baseRequest.put("/account", params),
  getAccountList: params =>
    baseRequest.get(
      "/account/list" + (params.accountType ? `?accountType=${params.accountType}` : "")
    ),
  deleteAccount: params => baseRequest.delete("/account", params)
};

export default accountService;
