import baseRequest from "../_core/baseRequest";

const userService = {
  register: params => baseRequest.post("/public/register", params),
  login: params =>
    baseRequest.post("/public/login", params).then(result => {
      baseRequest.addAuthToken(result.data.token);
      return result;
    }),
  getUser: params => baseRequest.get("/user"),
  updateUser: params => baseRequest.put("/user", params),
  changePassword: params => baseRequest.put("/user/changePassword", params)
};

export default userService;
