import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Formik, FieldArray, getIn } from "formik";
import * as yup from "yup";
import moment from "moment";

import history from "../../../_core/history";
import * as accountingActions from "../../../store/actions/accountingActions";
import * as breadcrumbActions from "../../../store/actions/breadcrumbActions";
import AccountSelectBox from "../SelectBoxes/AccountSelectBox";
import {
  defConfirmModalProps,
  defErrorModalProps,
  defSuccessModalProps
} from "../../../constants/defaultModalProps";
import {
  getTransactionURLByValue,
  getTransactionNameByValue
} from "../../../util/transactionHelper";

import { Input, Form, Button, Row, Col, Select, DatePicker, Modal, Radio } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import transactionTypes from "../../../constants/transactionTypes";

const Option = Select.Option;
const dateFormat = "DD/MM/YYYY";

interface IProps {
  transactionId?: string;
  type: string;
  isNew: boolean;
  transactionCE: any;
  updateTransaction: any;
  deleteTransaction: any;
  defaultAccount: any;
  getTransaction: (params: { transactionId?: string }) => any;
  setBreadcrumbRoutes: (routes: Route[]) => any;
}

interface IState {
  bill: any;
  isEdit: boolean;
}

class BillCEV extends Component<IProps, IState> {
  private formik = React.createRef<Formik>();
  constructor(props) {
    super(props);
    this.state = {
      bill: {
        account: "",
        date: undefined,
        expiryDate: undefined,
        note: "",
        currency: "₺",
        services: [{ unitPayment: undefined, amount: 1, tax: 0, account: "", note: "" }]
      },
      isEdit: false
    };
  }

  componentDidMount = async () => {
    const { transactionId, defaultAccount, isNew } = this.props;
    if (!isNew) {
      const transaction = (await this.props.getTransaction({ transactionId })).action.payload.data;
      const bill = {
        account: transaction.entries[0].account,
        date: moment(transaction.date).toDate(),
        expiryDate: moment(transaction.expiryDate).toDate(),
        note: transaction.note,
        currency: transaction.currency,
        services: transaction.entries.filter((item, index) => index !== 0)
      };
      this.setState({ bill });
    } else {
      this.setState({ isEdit: true });
    }
    if (defaultAccount) {
      const bill = { ...this.state.bill, account: defaultAccount };
      this.setState({ bill });
    }
    this.setBreadcrumb();
  };

  setBreadcrumb = () => {
    const { type, isNew, transactionId } = this.props;
    const {
      bill: { note }
    } = this.state;
    const routes: Route[] = [
      {
        path: "/islemler",
        breadcrumbName: "İşlemler"
      },
      {
        path: "/islemler/" + (type ? getTransactionURLByValue(type) || "" : ""),
        breadcrumbName: type ? getTransactionNameByValue(type) || "" : "",
        children: transactionTypes.map(transaction => {
          return { path: "/islemler/" + transaction.urlName, breadcrumbName: transaction.name };
        })
      },
      {
        path:
          "/islemler/" +
          (type ? getTransactionURLByValue(type) || "" : "") +
          "/" +
          (isNew ? "yeni" : transactionId || ""),
        breadcrumbName: (isNew ? "Yeni" : note) || ""
      }
    ];
    this.props.setBreadcrumbRoutes(routes);
  };

  getSum = (services, isTax) => {
    let sum = 0;
    services.forEach(service => {
      if (isTax) sum += (service.amount * service.unitPayment * service.tax) / 100;
      else sum += service.amount * service.unitPayment;
    });
    sum = Math.round(sum * 100) / 100;
    return sum || 0;
  };

  saveBill = async (values, { setSubmitting }) => {
    const { type, transactionId, isNew } = this.props;
    let total = 0;
    let transaction = {
      transactionType: type,
      date: values.date,
      expiryDate: values.expiryDate,
      note: values.note,
      currency: values.currency,
      entries: [{}]
    };

    values.services.forEach(service => {
      const calc =
        service.unitPayment * service.amount +
        (service.unitPayment * service.amount * service.tax) / 100;
      let debitCredit;
      let note;
      if (type === "bill") {
        debitCredit = {
          debit: 0,
          credit: calc
        };
        note = "Fatura Kalemi";
      } else {
        debitCredit = {
          debit: calc,
          credit: 0
        };
        note = "Gider Belgesi Kalemi";
      }
      total += calc;
      transaction.entries.push({ ...service, ...debitCredit, note });
    });
    if (type === "bill") {
      transaction.entries[0] = {
        account: values.account,
        debit: total,
        credit: 0,
        note: "Müşteri"
      };
    } else {
      transaction.entries[0] = {
        account: values.account,
        debit: 0,
        credit: total,
        note: "Tedarikçi"
      };
    }

    try {
      if (!isNew) {
        await this.props.updateTransaction({
          ...transaction,
          _id: transactionId,
          date: moment(values.date, dateFormat),
          expiryDate: moment(values.expiryDate, dateFormat)
        });
      } else {
        await this.props.transactionCE({
          ...transaction,
          date: moment(values.date, dateFormat),
          expiryDate: moment(values.expiryDate, dateFormat)
        });
      }
      Modal.success({
        ...defSuccessModalProps,
        content: `${getTransactionNameByValue(type)} ${!isNew ? "Güncellendi" : "Oluşturuldu"}`,
        onOk: () => {
          history.push(`/islemler/${getTransactionURLByValue(type)}`);
        }
      });
    } catch (err) {
      Modal.error({ ...defErrorModalProps, content: err.message });
    } finally {
      setSubmitting(false);
    }
  };

  deleteBill = async () => {
    const { transactionId, type } = this.props;
    Modal.confirm({
      ...defConfirmModalProps,
      okType: "danger",
      okText: "Sil",
      content: `Bu ${
        type === "bill" ? "faturayı" : "gider belgesini"
      } silmek istediğinizden emin misiniz?`,
      onOk: async () => {
        try {
          await this.props.deleteTransaction({ transactionId });
          Modal.success({
            ...defSuccessModalProps,
            content: `${type === "bill" ? "Fatura" : "Gider belgesi"} silindi.`,
            onOk: () => {
              history.push(`/islemler/${getTransactionURLByValue(type)}`);
            }
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  render() {
    const { type, isNew } = this.props;
    const { bill, isEdit } = this.state;
    const { account, date, expiryDate, note, currency, services } = bill;
    const taxRates = [0, 1, 8, 18];
    return (
      <Formik
        enableReinitialize
        ref={this.formik}
        initialValues={{
          account,
          date,
          expiryDate,
          note,
          currency,
          services
        }}
        validationSchema={billValidation}
        onSubmit={this.saveBill}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          setFieldValue,
          handleSubmit,
          resetForm
        }) => (
          <Form>
            {!isNew && (
              <Row type="flex" justify="end">
                <Col style={{ marginRight: 10 }}>
                  <Button onClick={this.deleteBill} disabled={isEdit} type="danger" icon="delete" />
                </Col>
                <Col>
                  <Button
                    onClick={() => {
                      this.setState({ isEdit: !isEdit });
                      resetForm();
                    }}
                    type="primary"
                    icon="edit"
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col>
                <Form.Item
                  colon={false}
                  label={type === "bill" ? "Müşteri" : "Tedarikçi"}
                  validateStatus={errors.account && touched.account ? "error" : "success"}
                  help={errors.account && touched.account ? errors.account : null}
                >
                  <AccountSelectBox
                    isEdit={isEdit}
                    accountTypes={type === "bill" ? "customer" : "supplier"}
                    value={values.account}
                    onSelectedAccount={({ value }) => setFieldValue("account", value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item
                  colon={false}
                  label="Açıklama"
                  validateStatus={errors.note && touched.note ? "error" : "success"}
                  help={errors.note && touched.note ? errors.note : null}
                >
                  <InputField
                    disabled={!isEdit}
                    name="note"
                    type="text"
                    value={values.note}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8} type="flex">
              <Col>
                <Form.Item
                  colon={false}
                  label="Düzenlenme Tarihi"
                  validateStatus={errors.date && touched.date ? "error" : "success"}
                  help={errors.date && touched.date ? errors.date : null}
                >
                  <DateField
                    disabled={!isEdit}
                    placeholder="Tarih seçiniz"
                    value={values.date ? moment(values.date, dateFormat) : undefined}
                    onChange={date => {
                      if (date) setFieldValue("date", moment(date).format(dateFormat));
                      else setFieldValue("date", "");
                      setFieldValue("expiryPresetAsDays", undefined);
                    }}
                    format={dateFormat}
                    name="date"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row type="flex">
              <Col>
                <Form.Item
                  colon={false}
                  label="Vade Tarihi"
                  validateStatus={errors.expiryDate && touched.expiryDate ? "error" : "success"}
                  help={errors.expiryDate && touched.expiryDate ? errors.expiryDate : null}
                >
                  <Row>
                    <Radio.Group
                      // buttonStyle="solid"
                      value={values.expiryPresetAsDays}
                      onChange={e => {
                        const date = values.date;
                        const expiryPresetAsDays = e.target.value;
                        setFieldValue("expiryPresetAsDays", expiryPresetAsDays);
                        setFieldValue(
                          "expiryDate",
                          date
                            ? moment(date, dateFormat)
                                .add(expiryPresetAsDays, "d")
                                .format(dateFormat)
                            : ""
                        );
                      }}
                    >
                      <Radio.Button value={0}>Aynı Gün</Radio.Button>
                      <Radio.Button value={7}>7 Gün</Radio.Button>
                      <Radio.Button value={14}>14 Gün</Radio.Button>
                      <Radio.Button value={30}>30 Gün</Radio.Button>
                      <Radio.Button value={60}>60 Gün</Radio.Button>
                    </Radio.Group>
                  </Row>
                  <Row>
                    <DateField
                      disabled={!isEdit}
                      placeholder="Tarih seçiniz"
                      value={values.expiryDate ? moment(values.expiryDate, dateFormat) : undefined}
                      onChange={expiryDate => {
                        if (expiryDate)
                          setFieldValue("expiryDate", moment(expiryDate).format(dateFormat));
                        else setFieldValue("expiryDate", "");

                        setFieldValue("expiryPresetAsDays", undefined);
                      }}
                      format={dateFormat}
                      name="expiryDate"
                    />
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row>
                  <Col md={8}>
                    <Form.Item
                      colon={false}
                      label="Para birimi değiştir"
                      validateStatus={
                        getIn(errors, `currency`) && getIn(touched, `currency`)
                          ? "error"
                          : "success"
                      }
                      help={
                        getIn(errors, `currency`) && getIn(touched, `currency`)
                          ? getIn(errors, `currency`)
                          : null
                      }
                    >
                      <Select
                        disabled={!!id}
                        id="currency"
                        onChange={value => setFieldValue("currency", value)}
                        style={{
                          width: "100%",
                          fontFamily: "Arcon,Harabara !important",
                          color: "rgba(0, 0, 0, 0.65)"
                        }}
                        value={values.currency}
                      >
                        {["₺", "$", "€", "£"].map((item, i) => {
                          return (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row> */}
            <FieldArray
              name="services"
              render={arrayHelpers => (
                <div>
                  {values.services.map((service, index) => (
                    <Row key={index} gutter={8} type="flex">
                      <Col md={6} xs={6}>
                        <Form.Item
                          colon={false}
                          label={index === 0 && `${getTransactionNameByValue(type)} kalemi`}
                          validateStatus={
                            getIn(errors, `services.${index}.account`) &&
                            getIn(touched, `services.${index}.account`)
                              ? "error"
                              : "success"
                          }
                          help={
                            getIn(errors, `services.${index}.account`) &&
                            getIn(touched, `services.${index}.account`)
                              ? getIn(errors, `services.${index}.account`)
                              : null
                          }
                        >
                          <AccountSelectBox
                            isEdit={isEdit}
                            accountTypes={type === "bill" ? "service-stock" : "employee-tax-bank"}
                            value={service.account}
                            onSelectedAccount={({ value, unitPayment }) => {
                              setFieldValue(`services.${index}.account`, value);
                              setFieldValue(`services.${index}.unitPayment`, unitPayment);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          colon={false}
                          label={index === 0 && "Miktar"}
                          validateStatus={
                            getIn(errors, `services.${index}.amount`) &&
                            getIn(touched, `services.${index}.amount`)
                              ? "error"
                              : "success"
                          }
                          help={
                            getIn(errors, `services.${index}.amount`) &&
                            getIn(touched, `services.${index}.amount`)
                              ? getIn(errors, `services.${index}.amount`)
                              : null
                          }
                        >
                          <InputField
                            addonAfter={values.currency}
                            disabled={!isEdit}
                            name={`services.${index}.amount`}
                            type="number"
                            min={0}
                            value={service.amount}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item
                          colon={false}
                          label={index === 0 && "Birim fiyatı"}
                          validateStatus={
                            getIn(errors, `services.${index}.unitPayment`) &&
                            getIn(touched, `services.${index}.unitPayment`)
                              ? "error"
                              : "success"
                          }
                          help={
                            getIn(errors, `services.${index}.unitPayment`) &&
                            getIn(touched, `services.${index}.unitPayment`)
                              ? getIn(errors, `services.${index}.unitPayment`)
                              : null
                          }
                        >
                          <InputField
                            addonAfter={values.currency}
                            disabled={!isEdit}
                            name={`services.${index}.unitPayment`}
                            type="number"
                            value={service.unitPayment}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={values.services.length > 1 && isEdit ? 2 : 3}>
                        <Form.Item
                          colon={false}
                          label={index === 0 && "KDV oranı"}
                          validateStatus={
                            getIn(errors, `services.${index}.tax`) &&
                            getIn(touched, `services.${index}.tax`)
                              ? "error"
                              : "success"
                          }
                          help={
                            getIn(errors, `services.${index}.tax`) &&
                            getIn(touched, `services.${index}.tax`)
                              ? getIn(errors, `services.${index}.tax`)
                              : null
                          }
                        >
                          <SelectField
                            disabled={!isEdit}
                            suffixIcon="%"
                            id={`services.${index}.tax`}
                            onChange={value => {
                              setFieldValue(`services.${index}.tax`, value);
                            }}
                            value={service.tax}
                            style={{ color: "rgba(0, 0, 0, 0.65)", textAlign: "right" }}
                          >
                            {taxRates.map((item, i) => {
                              return (
                                <Option key={item} value={item} style={{ textAlign: "right" }}>
                                  {item}
                                </Option>
                              );
                            })}
                          </SelectField>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item colon={false} label={index === 0 && "Fiyat"}>
                          <SummaryCol>
                            {this.getSum([service], false)} {values.currency}
                          </SummaryCol>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item colon={false} label={index === 0 && "Kdv'li fiyat"}>
                          <SummaryCol>
                            {this.getSum([service], true)} {values.currency}
                          </SummaryCol>
                        </Form.Item>
                      </Col>
                      <Col span={3}>
                        <Form.Item colon={false} label={index === 0 && "Ara toplam"}>
                          <SummaryCol>
                            {this.getSum([service], false) + this.getSum([service], true)}
                            {values.currency}
                          </SummaryCol>
                        </Form.Item>
                      </Col>
                      <Col span={values.services.length > 1 && isEdit ? 1 : 0}>
                        {isEdit && (
                          <Form.Item label={index === 0 && "Sil"}>
                            <Button
                              type="danger"
                              size="small"
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            >
                              -
                            </Button>
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  ))}

                  <Row>
                    {isEdit && (
                      <Button
                        size="small"
                        type="primary"
                        onClick={() =>
                          arrayHelpers.push({
                            unitPayment: undefined,
                            note: "",
                            amount: 1,
                            tax: 0,
                            account: ""
                          })
                        }
                      >
                        +
                      </Button>
                    )}
                  </Row>
                </div>
              )}
            />

            <Row type="flex" justify="end" align="middle" gutter={8}>
              <Col md={3}>Fiyat toplam:</Col>
              <Col md={3}>
                <SummaryCol>
                  {this.getSum(values.services, false)}
                  {values.currency}
                </SummaryCol>
              </Col>
            </Row>

            {taxRates.map(rate => {
              const sum = this.getSum(
                values.services.filter(service => service.tax === rate),
                true
              );
              if (sum === 0) return null;
              return (
                <Row type="flex" justify="end" align="middle" gutter={8}>
                  <Col md={3}>{rate} % kdv:</Col>
                  <Col md={3}>
                    <SummaryCol>
                      {sum}
                      {values.currency}
                    </SummaryCol>
                  </Col>
                </Row>
              );
            })}

            <Row
              type="flex"
              justify="end"
              align="middle"
              gutter={8}
              style={{ marginTop: 20, fontSize: 18 }}
            >
              <Col md={3}>Genel toplam:</Col>
              <Col md={3}>
                <SummaryCol style={{ fontSize: 20 }}>
                  {this.getSum(values.services, false) + this.getSum(values.services, true)}
                  {values.currency}
                </SummaryCol>
              </Col>
            </Row>
            <Row type="flex" justify="end">
              <Button
                key="submit"
                type="primary"
                disabled={isSubmitting || !isEdit}
                onClick={() => {
                  handleSubmit();
                }}
                style={{ marginTop: 20 }}
              >
                {!isNew ? "Güncelle" : "Kaydet"}
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

const dispatchToProps = dispatch => {
  return {
    transactionCE: params => dispatch(accountingActions.transactionCE(params)),
    getTransaction: params => dispatch(accountingActions.getTransaction(params)),
    updateTransaction: params => dispatch(accountingActions.updateTransaction(params)),
    deleteTransaction: params => dispatch(accountingActions.deleteTransaction(params)),
    setBreadcrumbRoutes: routes => dispatch(breadcrumbActions.setBreadcrumbRoutes(routes))
  };
};

export default connect(null, dispatchToProps)(BillCEV);

const SummaryCol = styled.div`
  margin: 4px 0;
  height: 32px;
  line-height: 30px;
  padding: 0 10px;
  border-radius: 4px;
  background: #fafafa;
  overflow: overlay;
  border: 1px solid #d9d9d9;
  text-align: right;
`;

const DateField = styled(DatePicker)`
  .ant-calendar-picker-input.ant-input {
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

const InputField = styled(Input)<{ type: string }>`
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
  }
  text-align: ${props => (props.type === "number" ? "right" : "left")} !important;
  color: rgba(0, 0, 0, 0.65) !important;
`;

const SelectField = styled(Select)`
  .ant-select-selection-selected-value {
    float: right !important;
  }
  color: rgba(0, 0, 0, 0.65) !important;
`;

const billValidation = yup.object().shape({
  account: yup.string().required("Bu alan zorunludur!"),
  currency: yup.string().required("Bu alan zorunludur!"),
  date: yup.string().required("Bu alan zorunludur!"),
  expiryPresetAsDays: yup.number(),
  expiryDate: yup
    .string()
    .required("Bu alan zorunludur!")
    .test("isGreater", "Düzenlenme tarihinden önceki bir tarih olamaz.", function(value) {
      return moment(value, dateFormat).isSameOrAfter(moment(this.parent.date, dateFormat));
    }),
  services: yup
    .array()
    .of(
      yup.object().shape({
        unitPayment: yup
          .number()
          .positive("0'dan büyük bir değer olmalı!")
          .required("Bu alan zorunludur!")
          .typeError("Bu alan sayı olmalıdır"),
        account: yup.string().required("Bu alan zorunludur!"),
        amount: yup
          .number()
          .required("Bu alan zorunludur!")
          .typeError("Bu alan sayı olmalıdır"),
        tax: yup.string().required("Bu alan zorunludur!")
      })
    )
    .min(1, "En az 1 islem girilmelidir!")
    .required("Bu alanlar zorunludur!")
});
