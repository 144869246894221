import React, { Component } from "react";
import { Table } from "antd";
import moment from "moment";

import { getAccountCodeConverted } from "../../util/accountHelper";
import history from "../../_core/history";

const { Column } = Table;

interface IProps {
  billList: Array<object>;
  transactionType: string;
}

class BillList extends Component<IProps, any> {
  render() {
    const { billList = [], transactionType } = this.props;
    return (
      <React.Fragment>
        <Table
          scroll={{ y: "54vh" }}
          // size="middle"
          dataSource={billList}
          pagination={false}
          locale={{ emptyText: "Herhangi bir işlem bulunamadı!" }}
          onRow={(record: any, rowIndex) => {
            return {
              style: { cursor: "pointer" },
              onClick: event => {
                history.push(history.location.pathname + "/" + record._id);
              }
            };
          }}
          expandedRowRender={(record, index, indent, expanded) => {
            const entries = [...record.entries];
            entries.shift();
            return (
              <div>
                <Table bordered dataSource={entries} pagination={false} size="middle">
                  <Column
                    title="Hesap Kodu"
                    dataIndex="accountCode"
                    render={(text, row) => getAccountCodeConverted(text)}
                  ></Column>
                  <Column title="Miktar" dataIndex="amount"></Column>
                  <Column title="Birim Fiyatı" dataIndex="unitPayment"></Column>
                  <Column
                    title="KDV Oranı"
                    dataIndex="tax"
                    render={(text, row) => text + "%"}
                  ></Column>
                  <Column
                    title="Toplam"
                    dataIndex={transactionType === "bill" ? "credit" : "debit"}
                  ></Column>
                </Table>
              </div>
            );
          }}
        >
          <Column
            align="left"
            title={transactionType === "bill" ? "Müşteri" : "Tedarikçi"}
            dataIndex="entries[0].accountCode"
            render={(text, row) => <span>{getAccountCodeConverted(text)}</span>}
          />
          <Column align="left" title="Açıklama" dataIndex="note" key="note" />
          <Column
            align="left"
            title="Vade Durumu"
            dataIndex="expiryDate"
            key="expiryDate"
            render={(text, row) => (text ? moment(text).format("DD/MM/YYYY") : "")}
          />

          <Column
            align="left"
            title="Düzenlenme Tarihi"
            dataIndex="date"
            key="date"
            render={(text, row) => (text ? moment(text).format("DD/MM/YYYY") : "")}
          />
          <Column
            align="right"
            title="Belge Tutarı"
            dataIndex="entries[0]"
            key="entries"
            render={(text, row: any) =>
              text[transactionType === "bill" ? "credit" : "debit"] + row.currency
            }
          />
        </Table>
      </React.Fragment>
    );
  }
}

export default BillList;
