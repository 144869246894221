import accountService from "../../services/accountService";

export enum ActionTypes {
  CREATE_ACCOUNT = "CREATE_ACCOUNT",
  GET_ALL_ACCOUNT = "GET_ALL_ACCOUNT",
  GET_ACCOUNT_LIST = "GET_ACCOUNT_LIST",
  DELETE_ACCOUNT = "DELETE_ACCOUNT",
  GET_ACCOUNT = "GET_ACCOUNT",
  UPDATE_ACCOUNT = "UPDATE_ACCOUNT"
}

export function createAccount(params) {
  return {
    type: ActionTypes.CREATE_ACCOUNT,
    payload: accountService.createAccount(params)
  };
}

export function getAllAccount(params) {
  return {
    type: ActionTypes.GET_ALL_ACCOUNT,
    payload: accountService.getAllAccount(params)
  };
}

export function getAccount(params) {
  return {
    type: ActionTypes.GET_ACCOUNT,
    payload: accountService.getAccount(params)
  };
}

export function updateAccount(params) {
  return {
    type: ActionTypes.UPDATE_ACCOUNT,
    payload: accountService.updateAccount(params)
  };
}

export function getAccountList(params) {
  return {
    type: ActionTypes.GET_ACCOUNT_LIST,
    payload: accountService.getAccountList(params)
  };
}

export function deleteAccount(params) {
  return {
    type: ActionTypes.DELETE_ACCOUNT,
    payload: accountService.deleteAccount(params)
  };
}
