import userService from "../../services/userService";

export enum ActionTypes {
  REGISTER = "REGISTER",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  GET_USER = "GET_USER",
  UPDATE_USER = "UPDATE_USER",
  CHANGE_PASSWORD = "CHANGE_PASSWORD"
}

export function register(params) {
  return {
    type: ActionTypes.REGISTER,
    payload: userService.register(params)
  };
}

export function login(params) {
  return {
    type: ActionTypes.LOGIN,
    payload: userService.login(params)
  };
}

export function logout() {
  return {
    type: ActionTypes.LOGOUT
  };
}

export function getUser(params) {
  return {
    type: ActionTypes.GET_USER,
    payload: userService.getUser(params)
  };
}

export function updateUser(params) {
  return {
    type: ActionTypes.UPDATE_USER,
    payload: userService.updateUser(params)
  };
}

export function changePassword(params) {
  return {
    type: ActionTypes.CHANGE_PASSWORD,
    payload: userService.changePassword(params)
  };
}
