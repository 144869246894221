import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu } from "antd";

import * as userActions from "../../../store/actions/userActions";
import * as breadcrumbActions from "../../../store/actions/breadcrumbActions";
import history from "../../../_core/history";
import { SubMenuHeader, StyledIcon, SubMenu } from "./StyledHeader";
import logo from "../../../public/images/logo.png";
import logoMini from "../../../public/images/minilogo.png";

interface IProps {
  collapsed: boolean;
  currentPathname: string;
  logout: () => any;
  setBreadcrumbRoutes: (routes: []) => any;
}

interface IState {
  openKeys: string[];
  selectedKeys: string[];
}

class Sider extends Component<IProps, IState> {
  private rootSubmenuKeys: string[];
  private rootItemKeys: string[];
  private shouldSkip: boolean;
  private skipUpdate: boolean;
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [],
      selectedKeys: []
    };
    this.rootSubmenuKeys = ["/islemler", "/hesaplar", "/hesaplar/vergi"];
    this.rootItemKeys = [
      "/islemler/faturalar",
      "/islemler/gider-belgeleri",
      "/hesaplar/musteriler",
      "/hesaplar/tedarikciler",
      "/hesaplar/kasalar",
      "/hesaplar/bankalar",
      "/hesaplar/urunler",
      "/hesaplar/hizmetler",
      "/hesaplar/sermayeler",
      "/hesaplar/calisanlar",
      "/hesaplar/vergi.kdv",
      "/hesaplar/vergi.stopaj",
      "/hesaplar/vergi.gelir",
      "/islemler",
      "/hesaplar",
      "/profil",
      "/giris"
    ];
    this.shouldSkip = false;
    this.skipUpdate = false;
  }

  componentDidMount() {
    this.updateOpenAndSelectedKeys();
  }

  componentDidUpdate(
    prevProps: Readonly<IProps>,
    prevState: Readonly<IState>,
    snapshot?: any
  ): void {
    if (prevProps.currentPathname !== this.props.currentPathname) {
      this.updateOpenAndSelectedKeys();
    }
  }

  updateOpenAndSelectedKeys = () => {
    if (!this.skipUpdate) {
      const { currentPathname } = this.props;
      const openKeys = this.rootSubmenuKeys.filter(subMenuKey =>
        currentPathname.includes(subMenuKey)
      );
      const selectedKey = this.rootItemKeys.find(itemKey => currentPathname.includes(itemKey));
      this.setState({
        openKeys,
        selectedKeys:
          selectedKey && selectedKey !== "/" && selectedKey !== "/giris" ? [selectedKey] : []
      });
    }
    this.skipUpdate = false;
  };

  setShouldSkip = shouldSkip => {
    this.shouldSkip = shouldSkip;
  };
  getShouldSkip = () => this.shouldSkip;

  handleClick = ({ key }) => {
    this.setState({ selectedKeys: key !== "/" ? [key] : [] });
    this.skipUpdate = true;
    history.push(key);
    if (key === "/giris") {
      this.props.logout();
      this.props.setBreadcrumbRoutes([]);
    }
  };

  onTitleClick = ({ key, domEvent }) => {
    if (domEvent.target.id === "submenu-title") {
      this.setShouldSkip(true);
      this.skipUpdate = true;
      this.setState({ selectedKeys: [key] });
      history.push(key);
    }
  };

  onOpenChange = openKeys => {
    if (!this.getShouldSkip()) {
      const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.setState({ openKeys });
      } else {
        this.setState({
          openKeys: latestOpenKey ? [...this.state.openKeys, latestOpenKey] : []
        });
      }
    } else {
      const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
      if (latestOpenKey && !this.state.openKeys.includes(latestOpenKey))
        this.setState({ openKeys: [] });
    }
    this.setShouldSkip(false);
  };

  render() {
    const { openKeys, selectedKeys } = this.state;
    const { collapsed } = this.props;
    return (
      <Menu
        mode="inline"
        theme="light"
        openKeys={openKeys}
        selectedKeys={selectedKeys}
        onClick={this.handleClick}
        onOpenChange={this.onOpenChange}
        style={{ border: "none", background: "transparent" }}
      >
        <Menu.Item key="/" title="Anasayfa">
          <StyledIcon
            collapsed={collapsed}
            component={() => (
              <>
                <img
                  alt="Anasayfa"
                  src={collapsed ? logoMini : logo}
                  style={{
                    width: "95%",
                    marginBottom: 8
                  }}
                />
              </>
            )}
          />
        </Menu.Item>

        <SubMenu
          className={selectedKeys.includes("/islemler") ? "ant-menu-item-selected" : undefined}
          key="/islemler"
          onTitleClick={this.onTitleClick}
          title={
            <SubMenuHeader id="submenu-title">
              <StyledIcon
                style={{ pointerEvents: "none" }}
                type="transaction"
                collapsed={collapsed}
              />
              <span style={{ pointerEvents: "none" }}>İşlemler</span>
            </SubMenuHeader>
          }
        >
          <Menu.Item key="/islemler/faturalar">Faturalar</Menu.Item>
          <Menu.Item key="/islemler/gider-belgeleri">Gider Belgeleri</Menu.Item>
        </SubMenu>

        <SubMenu
          key="/hesaplar"
          className={selectedKeys.includes("/hesaplar") ? "ant-menu-item-selected" : undefined}
          onTitleClick={this.onTitleClick}
          title={
            <SubMenuHeader id="submenu-title">
              <StyledIcon style={{ pointerEvents: "none" }} type="audit" collapsed={collapsed} />
              <span style={{ pointerEvents: "none" }}>Hesaplar</span>
            </SubMenuHeader>
          }
        >
          <Menu.Item key="/hesaplar/musteriler">Müşteriler</Menu.Item>
          <Menu.Item key="/hesaplar/tedarikciler">Tedarikçiler</Menu.Item>
          <Menu.Item key="/hesaplar/kasalar">Kasalar</Menu.Item>
          <Menu.Item key="/hesaplar/bankalar">Bankalar</Menu.Item>
          <Menu.Item key="/hesaplar/urunler">Ürünler</Menu.Item>
          <Menu.Item key="/hesaplar/hizmetler">Hizmetler</Menu.Item>
          <Menu.Item key="/hesaplar/sermayeler">Sermaye</Menu.Item>
          <Menu.Item key="/hesaplar/calisanlar">Çalışanlar</Menu.Item>
          <SubMenu title="Vergiler" key="/hesaplar/vergi">
            <Menu.Item key="/hesaplar/vergi.kdv">KDV</Menu.Item>
            <Menu.Item key="/hesaplar/vergi.stopaj">Stopaj Vergisi</Menu.Item>
            <Menu.Item key="/hesaplar/vergi.gelir">Gelir Vergisi</Menu.Item>
          </SubMenu>
        </SubMenu>

        <Menu.Item key="/profil" title="Profil">
          <SubMenuHeader>
            <StyledIcon type="user" collapsed={collapsed} />
            <span>Profil</span>
          </SubMenuHeader>
        </Menu.Item>
        <Menu.Item key="/giris" title="Çıkış">
          <SubMenuHeader>
            <StyledIcon type="logout" collapsed={collapsed} />
            <span>Çıkış</span>
          </SubMenuHeader>
        </Menu.Item>
      </Menu>
    );
  }
}

const dispatchToProps = dispatch => ({
  logout: () => dispatch(userActions.logout()),
  setBreadcrumbRoutes: routes => dispatch(breadcrumbActions.setBreadcrumbRoutes(routes))
});

export default connect(null, dispatchToProps)(Sider);
