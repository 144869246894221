import React, { Component } from "react";
import moment from "moment";
import { Formik, FieldArray, getIn } from "formik";
import { connect } from "react-redux";
import styled from "styled-components";

import history from "../../../_core/history";
import { TransactionCEVValidationSchema } from "./TransactionCEVValidationSchema";
import * as accountingActions from "../../../store/actions/accountingActions";
import * as accountActions from "../../../store/actions/accountActions";
import * as breadcrumbActions from "../../../store/actions/breadcrumbActions";
import AccountSelectBox from "../SelectBoxes/AccountSelectBox";
import {
  defConfirmModalProps,
  defErrorModalProps,
  defSuccessModalProps
} from "../../../constants/defaultModalProps";

import { Row, Col, Input, Form, Button, DatePicker, Modal } from "antd";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";
import {
  getTransactionNameByValue,
  getTransactionURLByValue
} from "../../../util/transactionHelper";
import transactionTypes from "../../../constants/transactionTypes";

const { TextArea } = Input;
const dateFormat = "DD/MM/YYYY";

type entry = {
  note: string;
  credit: number;
  debit: number;
  account: string;
};

type transactionType = {
  date: string;
  note: string;
  entries: Array<entry>;
};

interface IProps {
  account: any;
  transactionId?: string;
  getTransaction: (params: { transactionId?: string }) => any;
  transactionCE: any;
  deleteTransaction: any;
  getAllAccount: (params) => any;
  updateTransaction: any;
  isNew: boolean;
  setBreadcrumbRoutes: (routes: Route[]) => any;
}

interface IState {
  transaction: {
    date: string | undefined;
    note: string | undefined;
    currency: string | undefined;
    entries: Array<entry> | undefined;
  };
  isEdit: boolean;
}

class TransactionCEV extends Component<IProps, IState> {
  private formik = React.createRef<Formik>();

  constructor(props) {
    super(props);
    this.state = {
      transaction: {
        date: undefined,
        note: undefined,
        currency: "₺",
        entries: [
          { note: "", debit: 0, credit: 0, account: "" },
          { note: "", debit: 0, credit: 0, account: "" }
        ]
      },
      isEdit: false
    };
  }

  async componentDidMount() {
    const { transactionId, isNew } = this.props;
    try {
      if (!isNew) {
        const transaction = await this.props.getTransaction({ transactionId });
        transaction.action.payload.data.date = moment(
          transaction.action.payload.data.date
        ).toDate();

        this.setState({ transaction: { ...transaction.action.payload.data } });
      } else {
        this.setState({ isEdit: true });
      }
      await this.props.getAllAccount({});
    } catch (err) {
      history.push("/");
    }
    this.setBreadcrumb();
  }

  setBreadcrumb = () => {
    const { isNew, transactionId } = this.props;
    const {
      transaction: { note }
    } = this.state;
    const routes: Route[] = [
      {
        path: "/islemler",
        breadcrumbName: "İşlemler"
      },
      {
        path: "/islemler/" + getTransactionURLByValue("transaction"),
        breadcrumbName: getTransactionNameByValue("transaction") || "",
        children: transactionTypes.map(transaction => {
          return { path: "/islemler/" + transaction.urlName, breadcrumbName: transaction.name };
        })
      },
      {
        path:
          "/islemler/" +
          (getTransactionURLByValue("transaction") || "") +
          "/" +
          (isNew ? "yeni" : transactionId || ""),
        breadcrumbName: (isNew ? "Yeni" : note) || ""
      }
    ];
    this.props.setBreadcrumbRoutes(routes);
  };

  saveTransaction = async (values, { setSubmitting }) => {
    const { transactionId, isNew } = this.props;
    let totalCredit = 0;
    let totalDebit = 0;
    values.entries.forEach(entry => {
      totalDebit += +entry.debit;
      totalCredit += +entry.credit;
    });
    if (!isNew) {
      values._id = transactionId;
      values.date = moment(values.date, dateFormat);
    }
    if (totalDebit === totalCredit) {
      try {
        if (!isNew) {
          await this.props.updateTransaction({
            transactionType: "transaction",
            ...values,
            date: moment(values.date, dateFormat),
            _id: transactionId
          });
        } else {
          await this.props.transactionCE({
            transactionType: "transaction",
            ...values,
            date: moment(values.date, dateFormat),
          });
        }

        Modal.success({
          ...defSuccessModalProps,
          content: `Mahsup Fişi ${!isNew ? "Güncellendi" : "Oluşturuldu"}`,
          onOk: () => {
            history.push("/islemler");
          }
        });
      } catch (err) {
        this.setState({ transaction: { ...values } });
        setSubmitting(false);
      }
    } else {
      this.setState({ transaction: { ...values } });

      Modal.error({
        ...defErrorModalProps,
        content: "Mahsup Fişinde toplam borç ile toplam alacak eşit olmalıdır.",
        onOk: () => {
          setSubmitting(false);
        }
      });
    }
  };

  deleteTransaction = async () => {
    const { transactionId } = this.props;
    Modal.confirm({
      ...defConfirmModalProps,
      content: "Bu mahsup fişini silmek istediğinizden emin misiniz?",
      okType: "danger",
      okText: "Sil",
      onOk: async () => {
        try {
          await this.props.deleteTransaction({ transactionId });
          Modal.success({
            ...defSuccessModalProps,
            content: "Mahsup fişi silindi.",
            onOk: () => {
              history.push("/islemler");
            }
          });
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  render() {
    const { isNew } = this.props;
    const { isEdit, transaction } = this.state;
    const { date, note, currency, entries = [] } = transaction;
    return (
      <Formik
        enableReinitialize
        ref={this.formik}
        initialValues={{
          date,
          note,
          entries,
          currency
        }}
        validationSchema={TransactionCEVValidationSchema}
        onSubmit={this.saveTransaction}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm
        }) => {
          let totalCredit = 0;
          let totalDebit = 0;
          return (
            <Form onSubmit={handleSubmit}>
              {!isNew && (
                <Row type="flex" justify="end">
                  <Col style={{ marginRight: 10 }}>
                    <Button
                      onClick={this.deleteTransaction}
                      disabled={isEdit}
                      type="danger"
                      icon="delete"
                    ></Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={() => {
                        this.setState({ isEdit: !isEdit });
                        resetForm();
                      }}
                      type="primary"
                      icon="edit"
                    ></Button>
                  </Col>
                </Row>
              )}
              <Row gutter={8} type="flex">
                <Col xs={24} md={14}>
                  <Form.Item
                    colon={false}
                    label="Tarih"
                    validateStatus={errors.date && touched.date ? "error" : "success"}
                    help={errors.date && touched.date ? errors.date : null}
                  >
                    <DateField
                      disabled={!isEdit}
                      placeholder="Tarih seçiniz"
                      value={values.date ? moment(values.date, dateFormat) : undefined}
                      onChange={date => {
                        date && setFieldValue("date", moment(date).format(dateFormat));
                        !date && setFieldValue("date", undefined);
                      }}
                      format={dateFormat}
                      name="date"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} type="flex">
                <Col xs={24} md={24}>
                  <Form.Item
                    colon={false}
                    label="Açıklama"
                    validateStatus={errors.note && touched.note ? "error" : "success"}
                    help={errors.note && touched.note ? errors.note : null}
                  >
                    <TextArea
                      disabled={!isEdit}
                      rows={4}
                      name="note"
                      onChange={handleChange}
                      value={values.note}
                      style={{ color: "rgba(0, 0, 0, 0.65)" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={8} type="flex">
                <Col xs={24} md={24}>
                  <FieldArray
                    name="entries"
                    render={arrayHelpers => (
                      <div>
                        {values.entries.map((entry, index) => {
                          totalDebit += +entry.debit;
                          totalCredit += +entry.credit;
                          return (
                            <Row gutter={8} type="flex" key={index} style={{ marginBottom: 5 }}>
                              <Col span={4}>
                                <Form.Item
                                  colon={false}
                                  label={index === 0 && "Hesap"}
                                  validateStatus={
                                    getIn(errors, `entries.${index}.account`) &&
                                    getIn(touched, `entries.${index}.account`)
                                      ? "error"
                                      : "success"
                                  }
                                  help={
                                    getIn(errors, `entries.${index}.account`) &&
                                    getIn(touched, `entries.${index}.account`)
                                      ? getIn(errors, `entries.${index}.account`)
                                      : null
                                  }
                                >
                                  <AccountSelectBox
                                    isEdit={isEdit}
                                    accountTypes={""}
                                    value={entry.account}
                                    onSelectedAccount={({ value }) =>
                                      setFieldValue(`entries.${index}.account`, value)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={isEdit && values.entries.length > 2 ? 11 : 12}>
                                <Form.Item
                                  colon={false}
                                  label={index === 0 && "Açıklama"}
                                  validateStatus={
                                    getIn(errors, `entries.${index}.note`) &&
                                    getIn(touched, `entries.${index}.note`)
                                      ? "error"
                                      : "success"
                                  }
                                  help={
                                    getIn(errors, `entries.${index}.note`) &&
                                    getIn(touched, `entries.${index}.note`)
                                      ? getIn(errors, `entries.${index}.note`)
                                      : null
                                  }
                                >
                                  <InputField
                                    name={`entries.${index}.note`}
                                    disabled={!isEdit}
                                    type="text"
                                    value={entry.note}
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  colon={false}
                                  label={index === 0 && "Alacak"}
                                  validateStatus={
                                    getIn(errors, `entries.${index}.credit`) &&
                                    getIn(touched, `entries.${index}.credit`)
                                      ? "error"
                                      : "success"
                                  }
                                  help={
                                    getIn(errors, `entries.${index}.credit`) &&
                                    getIn(touched, `entries.${index}.credit`)
                                      ? getIn(errors, `entries.${index}.credit`)
                                      : null
                                  }
                                >
                                  <InputField
                                    addonAfter={values.currency}
                                    name={`entries.${index}.credit`}
                                    disabled={!isEdit}
                                    type="number"
                                    value={entry.credit}
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={4}>
                                <Form.Item
                                  colon={false}
                                  label={index === 0 && "Borç"}
                                  validateStatus={
                                    getIn(errors, `entries.${index}.debit`) &&
                                    getIn(touched, `entries.${index}.debit`)
                                      ? "error"
                                      : "success"
                                  }
                                  help={
                                    getIn(errors, `entries.${index}.debit`) &&
                                    getIn(touched, `entries.${index}.debit`)
                                      ? getIn(errors, `entries.${index}.debit`)
                                      : null
                                  }
                                >
                                  <InputField
                                    addonAfter={values.currency}
                                    name={`entries.${index}.debit`}
                                    disabled={!isEdit}
                                    type="number"
                                    value={entry.debit}
                                    onChange={handleChange}
                                  />
                                </Form.Item>
                              </Col>
                              <Col span={isEdit && values.entries.length > 2 ? 1 : 0}>
                                {isEdit && (
                                  <Form.Item label={index === 0 && "Sil"}>
                                    <Button
                                      size="small"
                                      type="danger"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      -
                                    </Button>
                                  </Form.Item>
                                )}
                              </Col>
                            </Row>
                          );
                        })}
                        <Row gutter={8} type="flex">
                          <Col span={12}>
                            {isEdit && (
                              <Button
                                type="primary"
                                size="small"
                                onClick={() =>
                                  arrayHelpers.push({
                                    note: "",
                                    credit: 0,
                                    debit: 0,
                                    account: ""
                                  })
                                }
                              >
                                +
                              </Button>
                            )}
                          </Col>
                          <Col style={{ textAlign: "right" }} md={4} xs={4}>
                            Toplam:
                          </Col>
                          <Col md={4} xs={4} style={{ textAlign: "right" }}>
                            {totalDebit.toFixed(2)}
                          </Col>
                          <Col md={4} xs={4} style={{ textAlign: "right" }}>
                            <Col md={20} xs={20}>
                              {totalCredit.toFixed(2)}
                            </Col>
                          </Col>
                        </Row>

                        <Row>
                          <Col
                            span={24}
                            style={{ marginTop: 5, display: "flex", justifyContent: "center" }}
                          >
                            {typeof errors.entries === "string" ? (
                              <div style={{ color: "red" }}>{errors.entries}</div>
                            ) : null}
                          </Col>
                        </Row>
                      </div>
                    )}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="end">
                <Button
                  key="submit"
                  type="primary"
                  disabled={isSubmitting || !isEdit}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {!isNew ? "Güncelle" : "Kaydet"}
                </Button>
              </Row>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

const stateToProps = state => {
  return {
    account: state.account
  };
};

const dispatchToProps = dispatch => {
  return {
    getTransaction: params => dispatch(accountingActions.getTransaction(params)),
    transactionCE: params => dispatch(accountingActions.transactionCE(params)),
    deleteTransaction: params => dispatch(accountingActions.deleteTransaction(params)),
    getAllAccount: params => dispatch(accountActions.getAllAccount(params)),
    updateTransaction: params => dispatch(accountingActions.updateTransaction(params)),
    setBreadcrumbRoutes: routes => dispatch(breadcrumbActions.setBreadcrumbRoutes(routes))
  };
};

const DateField = styled(DatePicker)`
  .ant-calendar-picker-input.ant-input {
    color: rgba(0, 0, 0, 0.65) !important;
  }
`;

const InputField = styled(Input)<{ type: string }>`
  .ant-input[disabled] {
    color: rgba(0, 0, 0, 0.65);
  }
  text-align: ${props => (props.type === "number" ? "right" : "left")} !important;
  color: rgba(0, 0, 0, 0.65) !important;
`;
export default connect(stateToProps, dispatchToProps)(TransactionCEV);
