export const BreadcrumbActionTypes = {
  SET_BREADCRUMB_ROUTES: "SET_BREADCRUMB_ROUTES",
  RESET_BREADCRUMB_ROUTES: "RESET_BREADCRUMB_ROUTES"
};

export const setBreadcrumbRoutes = routes => ({
  type: BreadcrumbActionTypes.SET_BREADCRUMB_ROUTES,
  payload: routes
});

export const resetBreadcrumbRoutes = () => ({
  type: BreadcrumbActionTypes.RESET_BREADCRUMB_ROUTES
});
