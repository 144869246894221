const accountTypes = [
  {
    name: "Müşteri",
    value: "customer",
    urlName: "musteriler",
    transactionTypes: "bill"
  },
  {
    name: "Tedarikçi",
    value: "supplier",
    urlName: "tedarikciler",
    transactionTypes: "expenseDocument"
  },
  {
    name: "Kasa",
    value: "cash",
    urlName: "kasalar"
  },
  {
    name: "Banka",
    value: "bank",
    urlName: "bankalar"
  },
  {
    name: "Ürün",
    value: "stock",
    urlName: "urunler"
  },
  {
    name: "Hizmet",
    value: "service",
    urlName: "hizmetler"
  },
  {
    name: "Sermaye",
    value: "capital",
    urlName: "sermayeler"
  },
  {
    name: "Çalışan",
    value: "employee",
    urlName: "calisanlar"
  },
  {
    name: "KDV",
    value: "tax.kdv",
    urlName: "vergi.kdv"
  },
  {
    name: "Stopaj Vergisi",
    value: "tax.withholding-tax",
    urlName: "vergi.stopaj"
  },
  {
    name: "Gelir Vergisi",
    value: "tax.income-tax",
    urlName: "vergi.gelir"
  }
];

export default accountTypes;
