import baseRequest from "../_core/baseRequest";
const accountingService = {
  getTransactions: params =>
    baseRequest.get(
      `/accounting/transactions?startDate=${params.startDate}&endDate=${params.endDate}&searchText=${params.searchText}&skip=${params.skip}&limit=${params.limit}&transactionType=${params.transactionType}`
    ),
  getActions: params =>
    baseRequest.get(
      `/accounting/actions?skip=${params.skip}&limit=${params.limit}` +
        (params.account ? `&account=${params.account}` : "")
    ),
  getBalance: params =>
    baseRequest.get("/accounting/balance" + (params.account ? `?account=${params.account}` : "")),
  getTransaction: params => baseRequest.get(`/accounting/transaction?id=${params.transactionId}`),
  transactionCE: params => baseRequest.post("/accounting", params),
  updateTransaction: params => baseRequest.put("/accounting", params),
  deleteTransaction: params => baseRequest.delete("/accounting", params),
  getStats: params => baseRequest.get("/accounting/stats")
};

export default accountingService;
