import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Row, Col, Modal } from "antd";

import * as userActions from "../../store/actions/userActions";
import * as breadcrumbActions from "../../store/actions/breadcrumbActions";
import ProfileForms from "./ProfileForms";
import baseRequest from "../../_core/baseRequest";
import { defSuccessModalProps } from "../../constants/defaultModalProps";
import { Route } from "antd/lib/breadcrumb/Breadcrumb";

interface IProps {
  getUser: any;
  updateUser: any;
  changePassword: any;
  setBreadcrumbRoutes: (routes: Route[]) => any;
}

class ProfilePage extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      user: {}
    };
  }

  async componentDidMount() {
    await this.getUser();
    this.setBreadcrumb();
  }

  setBreadcrumb = () => {
    const routes: Route[] = [
      {
        path: "/profil",
        breadcrumbName: "Profil"
      }
    ];

    this.props.setBreadcrumbRoutes(routes);
  };
  getUser = () => {
    this.props.getUser().then(result => {
      const user = result.value.data;
      this.setState({ user });
    });
  };

  updateFullName = (values, { setSubmitting }) => {
    const { fullName } = values;
    this.props.updateUser({ fullName }).then(result => {
      setSubmitting(false);
      this.setState({ user: result.value.data });
      Modal.success({
        ...defSuccessModalProps,
        content: "Kullanıcı bilgileri değiştirildi."
      });
    });
  };

  updatePassword = async (values, { setSubmitting, resetForm }) => {
    const { newPassword, oldPassword } = values;
    try {
      await this.props.changePassword({ newPassword, oldPassword });
      // saveState({ user: { sessionToken: result.value.data.token, loggedIn: true } });
      setSubmitting(false);
      baseRequest.addAuthToken();
      Modal.success({
        ...defSuccessModalProps,
        content: "Parola değiştirildi."
      });
      resetForm();
    } catch (err) {
      console.error(err);
    }
  };

  render() {
    const { user } = this.state;
    return (
      <Row type="flex" justify="center">
        <Col span={22}>
          <StyledRow>
            <Col>
              <StyledTitle>Profil</StyledTitle>
            </Col>
          </StyledRow>

          <ProfileForms
            user={user}
            updateFullName={this.updateFullName}
            updatePassword={this.updatePassword}
          />
        </Col>
      </Row>
    );
  }
}

const dispatchToProps = dispatch => {
  return {
    getUser: params => dispatch(userActions.getUser(params)),
    updateUser: params => dispatch(userActions.updateUser(params)),
    changePassword: params => dispatch(userActions.changePassword(params)),
    setBreadcrumbRoutes: routes => dispatch(breadcrumbActions.setBreadcrumbRoutes(routes))
  };
};

export default connect(null, dispatchToProps)(ProfilePage);

const StyledTitle = styled.h2`
  color: ${props => props.theme.colors.darkGray};
  font-size: calc(18px + 0.4vw);
  margin: 0;
`;

const StyledRow: any = styled(Row).attrs({
  type: "flex",
  justify: "space-between"
})`
  padding: 20px 0;
  @media (max-width: 400px) {
    margin-bottom: 15px;
  }
`;
