import React, { Component } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { Formik } from "formik";
import { Row, Col, Card, Button, Form, Input } from "antd";

interface IProps {
  user: any;
  updateFullName: any;
  updatePassword: any;
}

class ProfilePage extends Component<IProps, any> {
  private formik = React.createRef<Formik>();

  render() {
    const { user } = this.props;
    const { email, fullName } = user;
    return (
      <StyledCard bordered>
        <Row type="flex" justify="space-between">
          <Section>
            <SectionTitle>Kullanıcı Bilgileri</SectionTitle>
            <Formik
              enableReinitialize
              ref={this.formik}
              initialValues={{ fullName, email }}
              validationSchema={infoValidation}
              onSubmit={this.props.updateFullName}
            >
              {({ values, errors, isSubmitting, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Item
                        colon={false}
                        label="İsim soyisim"
                        validateStatus={errors.fullName ? "error" : "success"}
                        help={errors.fullName ? errors.fullName : null}
                      >
                        <Input
                          name="fullName"
                          type="text"
                          value={values.fullName}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item colon={false} label="E-mail adresi">
                        <Input
                          name="email"
                          type="mail"
                          value={values.email}
                          onChange={handleChange}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    onClick={e => {
                      handleSubmit();
                    }}
                    style={{ float: "right" }}
                    disabled={values.fullName === fullName || isSubmitting}
                  >
                    Kaydet
                  </Button>
                </Form>
              )}
            </Formik>
          </Section>
          <Section>
            <SectionTitle>Şifre Değiştir</SectionTitle>
            <Formik
              enableReinitialize
              ref={this.formik}
              initialValues={{ oldPassword: "", newPassword: "" }}
              validationSchema={passwordValidation}
              onSubmit={this.props.updatePassword}
            >
              {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <Form.Item
                        colon={false}
                        label="Eski şifre"
                        validateStatus={
                          errors.oldPassword && touched.oldPassword ? "error" : "success"
                        }
                        help={errors.oldPassword && touched.oldPassword ? errors.oldPassword : null}
                      >
                        <Input.Password
                          name="oldPassword"
                          type="text"
                          value={values.oldPassword}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        colon={false}
                        label="Yeni şifre"
                        validateStatus={
                          errors.newPassword && touched.newPassword ? "error" : "success"
                        }
                        help={errors.newPassword && touched.newPassword ? errors.newPassword : null}
                      >
                        <Input.Password
                          name="newPassword"
                          type="password"
                          value={values.newPassword}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        colon={false}
                        label="Yeni şifre tekrar"
                        validateStatus={
                          errors.newPasswordAgain && touched.newPasswordAgain ? "error" : "success"
                        }
                        help={
                          errors.newPasswordAgain && touched.newPasswordAgain
                            ? errors.newPasswordAgain
                            : null
                        }
                      >
                        <Input.Password
                          name="newPasswordAgain"
                          type="password"
                          value={values.newPasswordAgain}
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    onClick={e => {
                      handleSubmit();
                    }}
                    style={{ float: "right" }}
                    disabled={isSubmitting}
                  >
                    Kaydet
                  </Button>
                </Form>
              )}
            </Formik>
          </Section>
        </Row>
      </StyledCard>
    );
  }
}

export default ProfilePage;

const StyledCard = styled(Card)`
  box-shadow: 1px 4px 16px 2px ${props => props.theme.colors.shadowColor};
  border-radius: 10px !important;
`;

const Section = styled.div`
  width: 48%;
  padding: 5%;
  background: ${props => props.theme.colors.pattensBlue};
  border-radius: 10px;
  margin: 1%;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

const SectionTitle = styled.h3`
  color: #595959;
  font-weight: 600;
`;

const infoValidation = yup.object().shape({
  fullName: yup.string().required("Kullanıcı adı boş olamaz")
});

const passwordValidation = yup.object().shape({
  oldPassword: yup
    .string()
    .min(6, "Şifre en az 6 karakter olmalı")
    .required("Şifre boş olamaz"),
  newPassword: yup
    .string()
    .min(6, "Şifre en az 6 karakter olmalı")
    .required("Şifre boş olamaz"),
  newPasswordAgain: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Şifre uyuşmuyor")
    .required("Şifre tekrarı boş olamaz")
});
