export default {
  colors: {
    primaryBlack: "#000",
    primaryBlueOnHover: "#31bebf",
    white: "#FFF",
    midGray: "#737d85",
    pattensBlue: "#dee1e3",
    darkGray: "#595959",
    lightGray: "#d0d0d0",
    conifer: "#a9cf56",
    burntSienna: "#f16e52",
    moodyBlue: "#8781bd",
    blackRussian: "#222429",
    transparentWhite: "rgba(255,255,255,0.3)",
    homeImageOverlay: "rgba(114,139,144, 0.4)",
    whisper: "#ebebeb",
    transparentPrimary: "rgba(51, 203, 204, 0.99)",
    redWithOpacity: "rgba(255, 0, 0, 0.3)",
    red: "#f05959",
    primaryGreen: "#3BB088",
    secondaryGreen: "#9ed5ac",
    primaryBlue: "#359CDF",
    shadowColor: "#d2d2d2",
    logoColor: "#4fb087",
    bgGray: "#f0f2f5"
  },
  fullPageCentered: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "100vh",
    width: "100vw"
  }
};
