import { combineReducers } from "redux";

// import account from "./account";
import user from "./user";
import breadcrumb from "./breadcrumb";
const reducers = combineReducers({
  user,
  breadcrumb
});

export default reducers;
