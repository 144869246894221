const transactionTypes = [
  {
    name: "Mahsup Fişi",
    value: "transaction",
    urlName: "mahsup-fisleri"
  },
  {
    name: "Fatura",
    value: "bill",
    urlName: "faturalar"
  },
  {
    name: "Gider Belgesi",
    value: "expenseDocument",
    urlName: "gider-belgeleri"
  }
];

export default transactionTypes;
