import React, { Component } from "react";
import { Provider, connect } from "react-redux";
import { ThemeProvider } from "styled-components";

// import { IntlProvider } from "react-intl";
import Routes from "./Routes/Routes";
// import "antd/dist/antd.css";
import theme from "../styles/index";

class LanguageProvider extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Routes />
      </ThemeProvider>
    );
  }
}

class App extends Component<any> {
  render() {
    return (
      <Provider store={this.props.store}>
        <LanguageProviderX />
      </Provider>
    );
  }
}

function stateToProps(state) {
  return {};
}
const LanguageProviderX = connect(stateToProps)(LanguageProvider);

export default App;
