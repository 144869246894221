import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Input, Form, Button, message } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import * as userActions from "../../store/actions/userActions";
// import history from "../../_core/history";
// import baseRequest from "../../_core/baseRequest";
// import { saveState } from "../../_core/localStorage";

interface IProps {
  register: any;
  login: any;
  isRegister: boolean;
  setActiveKey: (key: string) => void;
}

interface IState {
  loginError?: string;
}

class AuthForm extends Component<IProps, IState> {
  /*constructor(props) {
    super(props);
    this.state = { loginError: undefined };
  }*/
  state = { loginError: undefined };
  authorize = async (values, { setSubmitting }) => {
    const { isRegister } = this.props;
    const { fullName, email, password } = values;
    if (isRegister) {
      try {
        await this.props.register({ fullName, email, password });
        message.success("Kayıt Başarılı");
        this.props.setActiveKey("login");
      } catch (err) {
        message.error(err.message);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        await this.props.login({ email, password });
      } catch (err) {
        this.setState({ loginError: err.message });
      } finally {
        setSubmitting(false);
      }
    }
  };

  render() {
    const { isRegister } = this.props;
    const { loginError } = this.state;
    return (
      <Formik
        initialValues={{ email: "", password: "", fullName: "", passwordAgain: "" }}
        validationSchema={isRegister ? registerValidation : loginValidation}
        onSubmit={this.authorize}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            {isRegister && (
              <FormItem
                label="İsim Soyisim"
                validateStatus={errors.fullName && touched.fullName ? "error" : "success"}
                help={errors.fullName && touched.fullName ? errors.fullName : null}
              >
                <Input
                  name="fullName"
                  type="text"
                  value={values.fullName}
                  onChange={handleChange}
                />
              </FormItem>
            )}
            <FormItem
              label="E-mail"
              validateStatus={errors.email && touched.email ? "error" : "success"}
              help={errors.email && touched.email ? errors.email : null}
            >
              <Input name="email" type="text" value={values.email} onChange={handleChange} />
            </FormItem>
            <FormItem
              label="Şifre"
              validateStatus={errors.password && touched.password ? "error" : "success"}
              help={errors.password && touched.password ? errors.password : null}
            >
              <Input.Password
                name="password"
                type="text"
                value={values.password}
                onChange={handleChange}
              />
            </FormItem>
            {isRegister && (
              <FormItem
                label="Şifre Tekrar"
                validateStatus={errors.passwordAgain && touched.passwordAgain ? "error" : "success"}
                help={errors.passwordAgain && touched.passwordAgain ? errors.passwordAgain : null}
              >
                <Input.Password
                  name="passwordAgain"
                  type="text"
                  value={values.passwordAgain}
                  onChange={handleChange}
                />
              </FormItem>
            )}
            {loginError && <span style={{ color: "red" }}>{"*" + loginError}</span>}
            <Button
              disabled={isSubmitting}
              onClick={() => {
                handleSubmit();
              }}
              htmlType="submit"
              style={{ position: "absolute", bottom: 60, right: 0 }}
            >
              {isRegister ? "Kaydol" : "Giriş Yap"}
            </Button>
          </Form>
        )}
      </Formik>
    );
  }
}

const dispatchToProps = dispatch => {
  return {
    register: params => dispatch(userActions.register(params)),
    login: params => dispatch(userActions.login(params))
  };
};

const stateToProps = state => {
  return {};
};

export default connect(stateToProps, dispatchToProps)(AuthForm);

const FormItem = styled(Form.Item)`
  margin-bottom:15px !important;  
  & > div > label {
    color: #696969 !important
    font-weight: 500;
  }
`;

const registerValidation = yup.object().shape({
  fullName: yup.string().required("Kullanıcı adı boş olamaz"),
  email: yup
    .string()
    .matches(/^.+@.+$/, "Geçersiz e-mail")
    .required("E-mail boş olamaz"),
  password: yup
    .string()
    .min(6, "Şifre en az 6 karakter olmalı")
    .required("Şifre boş olamaz"),
  passwordAgain: yup
    .string()
    .oneOf([yup.ref("password"), null], "Şifre uyuşmuyor")
    .required("Şifre tekrarı boş olamaz")
});

const loginValidation = yup.object().shape({
  email: yup
    .string()
    .matches(/^.+@.+$/, "Geçersiz e-mail")
    .required("E-mail boş olamaz"),
  password: yup
    .string()
    .min(6, "Şifre en az 6 karakter olmalı")
    .required("Şifre boş olamaz")
});
