import { Middleware } from "redux";
import { Modal } from "antd";
import { defErrorModalProps } from "../constants/defaultModalProps";
import * as userActions from "../store/actions/userActions";

const middleWare: Middleware = api => next => action => {
  if (action.type) {
    const error = /.*_ERROR$/;
    if (action.type.match(error)) {
      try {
        /*const message = action.payload.message;
        Modal.error({ title: "Hata", content: message });
        api.dispatch(alertActions.showError({ body: message, actionFunc: () => null }));*/
        if (action.payload.response && action.payload.response.data === "Unauthorized") {
          // saveState({});
          api.dispatch(userActions.logout());
          // window.location.reload();
        }
      } catch (error) {
        Modal.error({ ...defErrorModalProps, content: error.message });
        // api.dispatch(alertActions.showError({ body: "Something wrong happened" }));
      }
    }
  }
  return next(action);
};

export default function requestMiddleware() {
  return middleWare;
}
