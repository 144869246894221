import React, { Component } from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import AuthForm from "./AuthForm";
const { TabPane } = Tabs;

class AuthPage extends Component<any, { activeKey: string }> {
  state = { activeKey: "login" };

  setActiveKey = key => this.setState({ activeKey: key });

  render() {
    return (
      <Container>
        <img
          alt="Anasayfa"
          src="../images/logo.png"
          style={{
            height: 40
          }}
        />
        <StyledCard>
          <Tabs
            style={{ height: "inherit" }}
            defaultActiveKey="login"
            activeKey={this.state.activeKey}
            onTabClick={key => this.setActiveKey(key)}
          >
            <TabPane tab="Giriş Yap" key="login">
              <AuthForm isRegister={false} setActiveKey={this.setActiveKey} />
            </TabPane>
            <TabPane tab="Kaydol" key="register">
              <AuthForm isRegister setActiveKey={this.setActiveKey} />
            </TabPane>
          </Tabs>
        </StyledCard>
      </Container>
    );
  }
}

export default AuthPage;

const Container = styled.div`
  display: flex;
  height: calc(100vh - 40px);
  justify-content: space-around;
  align-items: center;
  @media (max-width: 450px) {
    flex-wrap: wrap;
  }
  @media (max-width: 320px) {
    height: calc(100vh - 10px);
    overflow: hidden;
  }
`;

const StyledCard = styled.div`
  box-shadow: 1px 4px 16px 2px ${props => props.theme.colors.shadowColor};
  border-radius: 10px !important;
  width: 50%;
  height: 600px;
  padding: 30px;
  background: white;
  @media (max-width: 400px) {
    height: 485px;
    width: 80%;
  }
`;
