import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import history from "../../_core/history";
import Main from "../Main";
import MainPage from "../HomePage/HomePage";
import AccountsPage from "../AccountingPage/AccountsPage";
import TransactionsPage from "../AccountingPage/TransactionsPage";
import TransactionDetailPage from "../AccountingPage/TransactionDetailPage";
import AuthPage from "../AuthPage/AuthPage";
import ProfilePage from "../ProfilePage/ProfilePage";
import AccountDetailsPage from "../AccountDetailsPage/AccountDetailsPage";
import AccountCEV from "../_defaultComponents/AccountComponents/AccountCEV";
import { UserType } from "../../types/generalTypes";
import { getAccountValueByURL } from "../../util/accountHelper";
import { getTransactionValueByURL } from "../../util/transactionHelper";

interface IProps {
  user: UserType;
}

interface IState {}

class Routes extends Component<IProps, IState> {
  render() {
    const loggedIn = this.props.user.loggedIn;
    return (
      <Main>
        <Router history={history}>
          <Switch>
            {!loggedIn && <Route path="/giris" render={() => <AuthPage />} />}

            {!loggedIn && <Route path="**" render={() => <Redirect to="/giris" />} />}

            <Route path="/" exact render={() => <MainPage />} />

            {/*<Route
              path="/islemler/:transactionType?/:id?"
              render={(props: any) => {
                const { defaultAccount } = (props.location.state as any) || false;
                return (
                  <TransactionsPage
                    id={props.match.params.id}
                    transactionType={props.match.params.transactionType}
                    defaultAccount={defaultAccount}
                  />
                );
              }}
            />*/}

            <Route
              path="/islemler"
              render={() => (
                <Switch>
                  <Route
                    exact
                    path="/islemler"
                    render={() => <TransactionsPage transactionType="transaction" />}
                  />
                  <Route
                    exact
                    path="/islemler/:transactionType"
                    render={props => {
                      const transactionType = getTransactionValueByURL(
                        props.match.params.transactionType
                      );
                      return <TransactionsPage transactionType={transactionType} />;
                    }}
                  />
                  <Route
                    exact
                    path="/islemler/:transactionType/yeni"
                    render={props => {
                      const transactionType = getTransactionValueByURL(
                        props.match.params.transactionType
                      );
                      return <TransactionDetailPage transactionType={transactionType} isNew />;
                    }}
                  />
                  <Route
                    exact
                    path="/islemler/:transactionType/:transactionId"
                    render={props => {
                      const transactionType = getTransactionValueByURL(
                        props.match.params.transactionType
                      );
                      const { defaultAccount } = (props.location.state as any) || false;
                      return (
                        <TransactionDetailPage
                          transactionType={transactionType}
                          transactionId={props.match.params.transactionId}
                          defaultAccount={defaultAccount}
                          isNew={false}
                        />
                      );
                    }}
                  />
                  <Route path="**" render={() => <Redirect to="/" />} />
                </Switch>
              )}
            />

            <Route
              path="/hesaplar"
              render={() => (
                <Switch>
                  <Route exact path="/hesaplar" render={() => <AccountDetailsPage />} />
                  <Route exact path="/hesaplar/yeni" render={() => <AccountCEV isNew />} />
                  <Route
                    exact
                    path="/hesaplar/:accountType"
                    render={props => {
                      const accountType = getAccountValueByURL(props.match.params.accountType);
                      return <AccountDetailsPage accountType={accountType} />;
                    }}
                  />
                  <Route
                    exact
                    path="/hesaplar/:accountType/yeni"
                    render={props => {
                      const accountType = getAccountValueByURL(props.match.params.accountType);
                      return <AccountCEV accountType={accountType} isNew />;
                    }}
                  />
                  <Route
                    exact
                    path="/hesaplar/:accountType/:accountId"
                    render={props => {
                      const accountType = getAccountValueByURL(props.match.params.accountType);
                      return (
                        <AccountCEV
                          accountId={props.match.params.accountId}
                          accountType={accountType}
                          isNew={false}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/hesaplar/:accountType/:accountId/islemler"
                    render={props => <AccountsPage accountId={props.match.params.accountId} />}
                  />
                  <Route path="**" render={() => <Redirect to="/" />} />
                </Switch>
              )}
            />

            {/*<Route
              path="/hesaplar/:accountType?/:id?/islemler"
              render={props => <AccountsPage accountId={props.match.params.id} />}
            />

            <Route
              path="/hesaplar/:accountType?/:id?"
              render={(props: any) => {
                let accountType, id;
                let isNew = false;
                if (props.match.params.accountType === "yeni") {
                  isNew = true;
                } else {
                  accountType = getAccountValueByURL(props.match.params.accountType);
                  if (props.match.params.id === "yeni") isNew = true;
                  else id = props.match.params.id;
                }
                return <AccountDetailsPage id={id} accountType={accountType} isNew={isNew} />;
              }}
            />*/}

            <Route path="/profil" render={() => <ProfilePage />} />

            <Route path="**" render={() => <Redirect to="/" />} />
          </Switch>
        </Router>
      </Main>
    );
  }
}

const stateToProps = state => ({ user: state.user });

export default connect(stateToProps, null)(Routes);
