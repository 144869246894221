import React, { Component } from "react";
import styled from "styled-components";
import { Row, Col, Card, Table, Input, Switch, Button } from "antd";

import {
  getAccountCodeConverted,
  getAccountUrlByAccountCode,
  getAccountNameByValue
} from "../../util/accountHelper";
import history from "../../_core/history";

const { Column } = Table;
const { Search } = Input;

interface IProps {
  dataSource: any;
  accountType: string;
  filterList: any;
}

interface IState {
  showDeleted: boolean;
  dataSourceAll: any;
  expandedRows: any;
  accountType: string;
}

class AccountList extends Component<IProps, any> {
  constructor(props) {
    super(props);
    this.state = { showDeleted: false, dataSourceFiltered: [], expandedRows: [] };
  }

  handleSwitch = () => {
    const { showDeleted } = this.state;
    if (showDeleted) this.props.filterList(showDeleted);
    else this.props.filterList(showDeleted);

    this.setState({ showDeleted: !showDeleted });
  };

  onSearch = value => {
    console.log(value);
  };

  componentDidMount = () => {
    const { accountType } = this.props;
    this.setState({ accountType });
  };

  componentDidUpdate = () => {
    const { accountType } = this.props;
    if (accountType !== this.state.accountType) {
      this.setState({ accountType, expandedRows: [] });
    }
  };

  render() {
    const { accountType, dataSource } = this.props;
    const { showDeleted } = this.state;

    return (
      <Row type="flex" justify="center" gutter={8}>
        <Col span={24}>
          <Row style={{ marginBottom: 20 }} type="flex" justify="space-between">
            <Col xs={24} md={5}>
              <Search placeholder="Ara" onSearch={this.onSearch} enterButton />
            </Col>
            <Col>
              Silinmiş hesapları göster
              <Switch
                style={{ marginLeft: 10 }}
                checked={showDeleted}
                onChange={this.handleSwitch}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Card bodyStyle={{ padding: 0 }} style={{ minHeight: "67vh" }}>
                <StyledTable
                  scroll={{ y: "60vh" }}
                  locale={{
                    emptyText: `Herhangi bir ${
                      accountType ? getAccountNameByValue(accountType) : "hesap"
                    } bilgisi bulunmamaktadır!`
                  }}
                  dataSource={dataSource}
                  onRow={(record: any, rowIndex) => {
                    return {
                      style: { background: record.isDeleted && "#ec8080", cursor: "pointer" },
                      onClick: event => {
                        const target = event.target as HTMLButtonElement;
                        if (target.id !== "button") {
                          history.push(
                            `/hesaplar/${getAccountUrlByAccountCode(record.accountCode)}/${
                              record._id
                            }`
                          );
                        }
                      }
                    };
                  }}
                  pagination={false}
                >
                  <Column
                    title="Hesap Adı"
                    dataIndex="accountName"
                    key="accountName"
                    render={(text, row) => <Button type="link">{text}</Button>}
                  />
                  <Column
                    title="Hesap Kodu"
                    dataIndex="accountCode"
                    key="accountCode"
                    render={(text, row) => getAccountCodeConverted(text) || "-"}
                  />
                  <Column
                    title="Bakiye"
                    dataIndex="balance"
                    key="balance"
                    align="right"
                    sorter={(a: any, b: any) => b.balance - a.balance}
                    sortDirections={["ascend"]}
                    render={(text, row) => (text ).toFixed(2) || "-"}
                  />
                  <Column
                    align="right"
                    title="İşlemler"
                    dataIndex="_id"
                    render={(text, row: any) => (
                      <Button
                        onClick={() => {
                          history.push(
                            "/hesaplar/" +
                              getAccountUrlByAccountCode(row.accountCode) +
                              "/" +
                              row._id +
                              "/islemler"
                          );
                        }}
                        id="button"
                        icon="transaction"
                      ></Button>
                    )}
                  />
                </StyledTable>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default AccountList;

const StyledTable = styled(Table)`
  .ant-table-body {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow-x: auto;
  }

  .ant-table-placeholder {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .ant-spin-nested-loading,
  .ant-spin-container,
  .ant-table {
    position: unset;
  }

  .ant-table-pagination.ant-pagination {
    margin: 16px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
`;
