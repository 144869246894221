import React, { Component } from "react";
import { connect } from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import { Row, Col, Table } from "antd";
import moment from "moment";

import * as accountingActions from "../../../store/actions/accountingActions";
import history from "../../../_core/history";
import { getAccountCodeConverted } from "../../../util/accountHelper";
import {
  getTransactionURLByValue,
  getTransactionNameByValue
} from "../../../util/transactionHelper";

const { Column } = Table;

interface IProps {
  getActions: any;
  getBalance: any;
  accountId: string;
}

interface IState {
  actionList: Array<object>;
  hasMoreAction: boolean;
  totalCredit: number;
  totalDebit: number;
  balance: number;
}

class AccountsPage extends Component<IProps, IState> {
  private iScroll: any;
  private iScrollDetail: any;
  private sInput: any;
  constructor(props) {
    super(props);
    this.state = {
      actionList: [],
      hasMoreAction: true,
      totalCredit: 0,
      totalDebit: 0,
      balance: 0
    };
  }

  getActionsOfAccount(page) {
    const { accountId } = this.props;
    page = page * 50;
    this.props.getActions({ account: accountId, skip: page, limit: 50 }).then(result => {
      const data = result.value.data;
      const oldActionList = this.state.actionList;
      const newActionList = [...oldActionList, ...data];
      if (data.length === 0) this.setState({ hasMoreAction: false });
      this.setState({ actionList: newActionList });
    });
  }

  getTotals() {
    const { accountId } = this.props;
    this.props.getBalance({ account: accountId }).then(result => {
      const data = result.value.data;
      this.setState({ totalCredit: data.credit, totalDebit: data.debit, balance: data.balance });
    });
  }

  componentDidMount = async () => {
    await this.getActionsOfAccount(0);
    await this.getTotals();
  };

  render() {
    const { actionList = [], hasMoreAction = true, totalCredit, totalDebit, balance } = this.state;

    return (
      <React.Fragment>
        <Row style={{ height: "68vh" }} type="flex" justify="center" gutter={24}>
          <Col>
            <Row
              style={{
                overflowY: "auto",
                height: "100%"
              }}
            >
              <InfiniteScroll
                ref={iScrollDetail => {
                  this.iScrollDetail = iScrollDetail;
                }}
                initialLoad={false}
                pageStart={0}
                loadMore={page => this.getActionsOfAccount(page)}
                hasMore={hasMoreAction}
                useWindow={false}
              >
                <Table
                  scroll={{ y: "55vh" }}
                  bordered
                  size="middle"
                  dataSource={actionList}
                  pagination={false}
                  locale={{
                    emptyText: "İşlem bulunamadı"
                  }}
                  onRow={(record: any, rowIndex) => {
                    const { transactionType } = record;
                    return {
                      style: { cursor: "pointer" },
                      onClick: event => {
                        const routing = getTransactionURLByValue(transactionType);
                        history.push(`/islemler/${routing}/${record._id}`);
                      }
                    };
                  }}
                >
                  <Column
                    align="left"
                    title="Hesap"
                    dataIndex="accountCode"
                    render={(text, row) => getAccountCodeConverted(text)}
                    ellipsis={true}
                    key="accountCode"
                  />

                  <Column
                    align="left"
                    title="Tarih"
                    dataIndex="date"
                    render={(text, row) => moment(text).format("DD/MM/YYYY")}
                    key="date"
                  />
                  <Column
                    align="left"
                    title="Açıklama"
                    dataIndex="note"
                    key="note"
                    ellipsis={true}
                  />
                  <Column
                    align="left"
                    title="İşlem Tipi"
                    dataIndex="transactionType"
                    key="transactionType"
                    ellipsis={true}
                    render={text => getTransactionNameByValue(text)}
                  />
                  <Column
                    align="right"
                    title="Borç"
                    dataIndex="debit"
                    render={(text, row: any) => `${text.toFixed(2)} ${row.currency}`}
                    key="credit"
                  />
                  <Column
                    align="right"
                    title="Alacak"
                    dataIndex="credit"
                    render={(text, row: any) => `${text.toFixed(2)} ${row.currency}`}
                    key="debit"
                  />
                  <Column
                    align="right"
                    title="Yürüyen Bakiye"
                    dataIndex="runningTotal"
                    render={(text, row: any) => `${text.toFixed(2)} ${row.currency}`}
                    key="runningTotal"
                  />
                </Table>
              </InfiniteScroll>

              <Row
                type="flex"
                justify="end"
                style={{
                  width: "100%",
                  padding: "20px 10px",
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  background: "#fafafa"
                }}
                // style={{ marginRight: 16, marginTop: 20 }}
              >
                <Col>Toplam:</Col>
                <Col style={{ textAlign: "end", width: "15%" }}>{totalDebit.toFixed(2)} ₺</Col>
                <Col style={{ textAlign: "end", width: "15%" }}>{totalCredit.toFixed(2)} ₺</Col>
                <Col style={{ textAlign: "end", width: "15%" }}>{balance.toFixed(2)} ₺</Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const dispatchToProps = dispatch => {
  return {
    getActions: params => dispatch(accountingActions.getActions(params)),
    getBalance: params => dispatch(accountingActions.getBalance(params))
  };
};

const stateToProps = state => {
  return {};
};

export default connect(stateToProps, dispatchToProps)(AccountsPage);
